import {
	getSwitcherValue,
	hasPermissions,
	permissionList,
	queryClient,
} from "helpers";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { currentUserService } from "services";

export const useCurrentUser = () => {
	const [page, setPage] = useState(1);

	const useCurrentUserQuery = () => {
		const queryResult = useQuery(
			"currentUser",
			currentUserService.getCurrentUser,
		);

		return { ...queryResult };
	};

	const useCurrentUsersListQuery = () => {
		const fetch = () =>
			currentUserService.getUsers({
				page,
			});

		const queryResult = useQuery(["currentUsersList", page], fetch, {
			keepPreviousData: true,
			staleTime: 5000,
			retry: false,
		});

		return { ...queryResult, page, setPage };
	};

	const useUsersSelectQuery = () => {
		const fetch = () =>
			currentUserService.getUsers({
				page: 1,
				page_size: 500,
				sort_by: "username",
				sort_desc: true,
			});

		const queryResult = useQuery("currentUsersDropdown", fetch, {
			enabled: hasPermissions([permissionList.canViewCurrentUsers]),
			retry: false,
			select: ({ items }) =>
				items.map(({ id, username }) => ({
					value: id,
					name: username,
				})),
		});

		return { ...queryResult };
	};

	const useUserUpdateQuery = alert => {
		return useMutation(data => currentUserService.updateProfile(data), {
			onSuccess() {
				alert.success("Info has been updated successfully.");
			},
			onError(error) {
				alert.error(error);
			},
		});
	};

	const useChangePasswordQuery = alert => {
		return useMutation(
			({ current_password, new_password }) => {
				return currentUserService.changePassword({
					current_password,
					new_password,
				});
			},
			{
				onSuccess() {
					alert.success("Password has been changed successfully.");
				},
				onError(error) {
					alert.error(error);
				},
			},
		);
	};

	const useAddUserQuery = alert => {
		return useMutation(
			({ terminal_id, is_allow_return, is_allow_void, ...values }) => {
				const user = {
					...values,
					is_allow_return: getSwitcherValue(is_allow_return),
					is_allow_void: getSwitcherValue(is_allow_void),
					terminal_id: terminal_id === "all" ? null : terminal_id,
				};
				return currentUserService.addUser(user);
			},
			{
				onSuccess() {
					alert.success("User has been added successfully.");
				},
				onError(error) {
					alert.error(error);
				},
			},
		);
	};

	const useGetUserQuery = id => {
		const fetch = () => currentUserService.getUserData(id);

		const queryResult = useQuery(["userById", id], fetch, {
			keepPreviousData: true,
			staleTime: 5000,
			retry: false,
			select: ({
				terminal_id,
				is_allow_return,
				is_allow_void,
				...data
			}) => ({
				...data,
				terminal_id: terminal_id === null ? "all" : terminal_id,
				is_allow_return: is_allow_return ? "yes" : "no",
				is_allow_void: is_allow_void ? "yes" : "no",
			}),
			onError(error) {
				//TODO: redirect to listing
				console.log(error);
			},
		});

		return { ...queryResult };
	};

	const useUpdateUserQuery = (id, alert) => {
		return useMutation(
			({
				terminal_id,
				is_allow_return,
				is_allow_void,
				password,
				...values
			}) =>
				currentUserService.updateUser(
					{
						...values,
						is_allow_return: getSwitcherValue(is_allow_return),
						is_allow_void: getSwitcherValue(is_allow_void),
						terminal_id: terminal_id === "all" ? null : terminal_id,
						password: !password ? null : password,
					},
					id,
				),
			{
				onSuccess() {
					alert.success("User has been updated successfully.");
				},
				onError(error) {
					alert.error(error);
				},
			},
		);
	};

	const useActivateUserQuery = alert => {
		return useMutation(id => currentUserService.activateUser(id), {
			async onSuccess() {
				alert.success("User has been activated successfully.");
				await queryClient.refetchQueries(["currentUsersList", page]);
			},
			onError(error) {
				alert.error(error);
			},
		});
	};

	const useDeactivateUserQuery = alert => {
		return useMutation(id => currentUserService.deactivateUser(id), {
			async onSuccess() {
				alert.success("User has been deactivated successfully.");
				await queryClient.refetchQueries(["currentUsersList", page]);
			},
			onError(error) {
				alert.error(error);
			},
		});
	};

	return {
		useCurrentUsersListQuery,
		useCurrentUserQuery,
		useUsersSelectQuery,
		useUserUpdateQuery,
		useChangePasswordQuery,
		useAddUserQuery,
		useGetUserQuery,
		useUpdateUserQuery,
		useActivateUserQuery,
		useDeactivateUserQuery,
	};
};
