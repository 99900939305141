import React, { useEffect, useMemo } from "react";
import {
	TableContainer,
	Table as MaUTable,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	CircularProgress,
	Pagination,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTable, useSortBy } from "react-table";

const PAGE_SIZE = 40;

export const CustomTable = ({
	columns = [],
	data = [],
	isLoading,
	handlePageChange,
	count,
	pageIndex,
	hover,
	stickyHeader,
	handleSortChange,
	disableSortBy,
	hidePagination,
	initialSortBy,
}) => {
	const pageCount = useMemo(() => Math.ceil(count / PAGE_SIZE) || 1, [count]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		state: { sortBy },
	} = useTable(
		{
			columns,
			data,
			manualSortBy: true,
			disableSortBy,
			disableSortRemove: true,
			initialState: {
				sortBy: initialSortBy || [],
			},

			manualPagination: true,
			pageCount: pageCount,
		},
		useSortBy,
	);

	useEffect(() => {
		handleSortChange?.(sortBy?.[0]);
	}, [handleSortChange, sortBy]);

	const body = () => {
		if (isLoading) {
			return (
				<TableRow>
					<TableCell align="center" colSpan="9">
						<Typography mt={5} mb={5}>
							<CircularProgress color="secondary" />
						</Typography>
					</TableCell>
				</TableRow>
			);
		} else if (!rows.length) {
			return (
				<TableRow>
					<TableCell align="center" colSpan="9">
						<Typography mt={5} mb={5}>
							There are no data
						</Typography>
					</TableCell>
				</TableRow>
			);
		} else {
			return rows.map(row => {
				prepareRow(row);
				return (
					<TableRow {...row.getRowProps()} hover={hover}>
						{row.cells.map(cell => {
							const column = cell.column;
							const classNameCell = column?.classNameCell;
							const disableOnPrint = column?.disableOnPrint && {
								displayPrint: "none",
							};

							const cellProps = cell.getCellProps({
								className: classNameCell,
							});

							return (
								<TableCell
									{...cellProps}
									align={column.align}
									sx={disableOnPrint}
								>
									{cell.render("Cell")}
								</TableCell>
							);
						})}
					</TableRow>
				);
			});
		}
	};

	return (
		<TableContainer>
			<MaUTable {...getTableProps()} stickyHeader={stickyHeader}>
				<TableHead>
					{headerGroups.map(headerGroup => (
						<TableRow {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => {
								const disableOnPrint =
									column?.disableOnPrint && {
										displayPrint: "none",
									};
								return (
									<TableCell
										{...column.getHeaderProps(
											column.getSortByToggleProps(),
										)}
										align={column.align}
										className={
											column.canSort ? "canSort" : ""
										}
										sx={disableOnPrint}
									>
										<span className="tableCell">
											<span>
												{column.render("Header")}
											</span>
											<span className="arrow">
												{column.canSort ? (
													column.isSorted ? (
														column.isSortedDesc ? (
															<ArrowDropDownIcon className="descArrow" />
														) : (
															<ArrowDropDownIcon className="ascArrow" />
														)
													) : (
														<ArrowDropDownIcon className="inactiveArrow" />
													)
												) : null}
											</span>
										</span>
									</TableCell>
								);
							})}
						</TableRow>
					))}
				</TableHead>
				<TableBody {...getTableBodyProps()}>{body()}</TableBody>
			</MaUTable>
			{!isLoading && rows.length > 0 && !hidePagination && (
				<Pagination
					color="primary"
					count={pageCount}
					page={pageIndex}
					siblingCount={1}
					boundaryCount={1}
					onChange={handlePageChange}
					showLastButton
					showFirstButton
					sx={{
						my: 2,
						justifyContent: "end",
						display: "flex",
						displayPrint: "none",
					}}
				/>
			)}
		</TableContainer>
	);
};
