import React from "react";
import { LocationForm } from "components";
import { useAlert } from "react-alert";
import { useLocation } from "queries";

const AddLocation = () => {
	const alert = useAlert();
	const { useAddLocationQuery, useAddLocationImageQuery } = useLocation();
	const { mutateAsync: handleAddLocation } = useAddLocationQuery(alert);
	const { mutateAsync: handleAddLocationImage } =
		useAddLocationImageQuery(alert);

	const handleOnSubmit = async (values, selectedFile) => {
		const { id } = await handleAddLocation(values);
		selectedFile &&
			(await handleAddLocationImage({ id, file: selectedFile }));
	};

	return (
		<>
			<h2>Create a new location</h2>
			<LocationForm onSubmit={handleOnSubmit} />
		</>
	);
};
export default AddLocation;
