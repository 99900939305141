import { axiosWithHandler } from "helpers";

export const currentTerminalService = {
	getTerminals,
	getTerminal,
};

const PAGE_SIZE = 40;

function getTerminals({ page, location_id = null, page_size }) {
	const options = {
		method: "get",
		url: "/api/companies/current/terminals",
		params: {
			page_index: page - 1,
			page_size: page_size || PAGE_SIZE,
			location_id,
		},
	};

	return axiosWithHandler(options);
}

function getTerminal({ id }) {
	const options = {
		method: "get",
		url: `/api/companies/current/terminals/${id}`,
	};

	return axiosWithHandler(options);
}
