import { axiosWithHandler } from "helpers";

export const terminalService = {
	getTerminals,
	getTerminalData,
	deleteTerminal,
	addTerminal,
	updateTerminal,
};

const PAGE_SIZE = 40;

function getTerminals({ page }) {
	const options = {
		method: "get",
		url: "/api/terminals",
		params: {
			page_index: page - 1,
			page_size: PAGE_SIZE,
		},
	};

	return axiosWithHandler(options);
}

function getTerminalData(id) {
	const options = {
		method: "get",
		url: `/api/terminals/${id}`,
	};

	return axiosWithHandler(options);
}

function deleteTerminal(id) {
	const options = {
		method: "delete",
		url: `/api/terminals/${id}`,
	};

	return axiosWithHandler(options);
}

function addTerminal(data) {
	const options = {
		method: "post",
		url: "/api/terminals",
		data,
	};

	return axiosWithHandler(options);
}

function updateTerminal(data, id) {
	const options = {
		method: "put",
		url: `/api/terminals/${id}`,
		data,
	};

	return axiosWithHandler(options);
}
