import { axiosWithHandler } from "helpers";

export const companyService = {
	getCompanies,
	getCompanyData,
	deleteCompany,
	addCompany,
	updateCompany,
	addCompanyImage,
};

const PAGE_SIZE = 40;

function getCompanies({ page, page_size }) {
	const options = {
		method: "get",
		url: "/api/companies",
		params: {
			page_index: page - 1,
			page_size: page_size || PAGE_SIZE,
		},
	};

	return axiosWithHandler(options);
}

function getCompanyData(id) {
	const options = {
		method: "get",
		url: `/api/companies/${id}`,
	};

	return axiosWithHandler(options);
}

function deleteCompany(id) {
	const options = {
		method: "delete",
		url: `/api/companies/${id}`,
	};

	return axiosWithHandler(options);
}

function addCompany(data) {
	const options = {
		method: "post",
		url: "/api/companies",
		data,
	};

	return axiosWithHandler(options);
}

function updateCompany(data, id) {
	const options = {
		method: "put",
		url: `/api/companies/${id}`,
		data,
	};

	return axiosWithHandler(options);
}

function addCompanyImage(id, document) {
	let changeLogoModel = new FormData();

	changeLogoModel.append("file", document);
	changeLogoModel.append("Name", document.name);
	changeLogoModel.append("FileName", document.name);
	changeLogoModel.append("Extension", document.name.split(/[.]+/).pop());

	const options = {
		method: "post",
		data: changeLogoModel,
		url: `/api/companies/${id}/image`,
	};
	return axiosWithHandler(options);
}
