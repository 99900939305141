import React from "react";
import { Link } from "react-router-dom";
import { BlobProvider } from "@react-pdf/renderer";
import { TransactionReceiptPdf } from "components";
import { canPrint, canRefresh } from "helpers";
import { IconButton, Box } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PrintIcon from "@mui/icons-material/Print";
import SyncIcon from "@mui/icons-material/Sync";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export const Actions = ({ data, print, refresh }) => {
	const isPrint = canPrint(data.status);
	const isRefresh = canRefresh(data.status);

	return (
		<Box display="flex">
			<IconButton
				color="primary"
				title="Transaction Details"
				component={Link}
				to={`/transactions/${data.id}`}
			>
				<VisibilityIcon />
			</IconButton>
			{isPrint && (
				<>
					<IconButton
						onClick={() => print(data)}
						color="primary"
						title="Print the receipt"
					>
						<PrintIcon />
					</IconButton>
					<BlobProvider
						document={<TransactionReceiptPdf data={data} />}
					>
						{({ url }) => (
							<IconButton
								component="a"
								color="primary"
								title="Download PDF receipt"
								href={url}
								target="_blank"
								rel="noopener noreferrer"
							>
								<PictureAsPdfIcon />
							</IconButton>
						)}
					</BlobProvider>
				</>
			)}
			{isRefresh && (
				<IconButton
					onClick={() => refresh(data.id)}
					color="primary"
					title="Refresh Transaction"
				>
					<SyncIcon />
				</IconButton>
			)}
		</Box>
	);
};
