import { axiosWithHandler } from "helpers";

export const locationService = {
	getLocations,
	getLocationData,
	deleteLocation,
	addLocation,
	updateLocation,
	addLocationImage,
};

const PAGE_SIZE = 40;

function getLocations({ page, company_id, page_size }) {
	const options = {
		method: "get",
		url: "/api/locations",
		params: {
			page_index: page - 1,
			page_size: page_size || PAGE_SIZE,
			company_id,
		},
	};

	return axiosWithHandler(options);
}

function getLocationData(id) {
	const options = {
		method: "get",
		url: `/api/locations/${id}`,
	};

	return axiosWithHandler(options);
}

function deleteLocation(id) {
	const options = {
		method: "delete",
		url: `/api/locations/${id}`,
	};

	return axiosWithHandler(options);
}

function addLocation(data) {
	const options = {
		method: "post",
		url: "/api/locations",
		data,
	};

	return axiosWithHandler(options);
}

function updateLocation(data, id) {
	const options = {
		method: "put",
		url: `/api/locations/${id}`,
		data,
	};

	return axiosWithHandler(options);
}

function addLocationImage(id, document) {
	let changeLogoModel = new FormData();

	changeLogoModel.append("file", document);
	changeLogoModel.append("Name", document.name);
	changeLogoModel.append("FileName", document.name);
	changeLogoModel.append("Extension", document.name.split(/[.]+/).pop());

	const options = {
		method: "post",
		data: changeLogoModel,
		url: `/api/locations/${id}/image`,
	};
	return axiosWithHandler(options);
}
