import React from "react";
import { Divider, Grid, Typography } from "@mui/material";

import {
	getDate,
	TRANSACTION_STATUS,
	formDataToObject,
	toFixed,
} from "helpers";

export const TransactionReceipt = React.forwardRef(({ data }, ref) => {
	const extData =
		(data?.response && formDataToObject(data?.response?.ext_data)) || {};
	const emvData =
		(data?.response && formDataToObject(data?.response?.emv_data)) || {};

	return (
		<Grid container className="receipt" ref={ref}>
			<Grid item xs={12}>
				<Typography component="h4" variant="subtitle1" my={1}>
					Customer Copy
				</Typography>
				<Divider />
			</Grid>
			<Grid item xs={12} my={1}>
				<Typography
					gutterBottom
					component="div"
					sx={{ textAlign: "center" }}
				>
					{data?.terminal?.name}
					<br />
					{data?.location?.address_line1}
					<br />
					{`${data?.location?.city} ${data?.location?.region_code} ${data?.location?.postal_code}`}
					<br />
					{data?.location?.phone}
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography gutterBottom component="div">
					{data?.created_at && getDate(data?.created_at)}
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography
					gutterBottom
					component="div"
					sx={{ textAlign: "right" }}
				>
					{data?.created_at && getDate(data?.created_at, "HH:mm")}
				</Typography>
			</Grid>
			<Grid item xs={12} sx={{ mb: 1, mt: 1 }}>
				{data?.status &&
					TRANSACTION_STATUS.map(
						(item, index) =>
							item.value === data?.status && (
								<Typography
									key={index}
									sx={{ textAlign: "center" }}
								>
									{item.name}
								</Typography>
							),
					)}
			</Grid>
			<Grid item xs={6} md={6} my={2}>
				<Typography gutterBottom component="div">
					Trans #: {data?.reference_id}
				</Typography>
			</Grid>
			<Grid item xs={6} md={6} my={2}>
				<Typography
					gutterBottom
					component="div"
					sx={{ textAlign: "right" }}
				>
					Batch #: {extData?.BatchNum}
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography gutterBottom component="div">
					{data?.credit_card?.card_network}
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography
					gutterBottom
					component="div"
					sx={{ textAlign: "right" }}
				>
					CHIP
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography gutterBottom component="div">
					*************{data?.credit_card?.last_four_digits}
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography
					gutterBottom
					component="div"
					sx={{ textAlign: "right" }}
				>
					**/**
				</Typography>
			</Grid>
			<Grid item xs={6} md={6} mt={1}>
				<Typography gutterBottom component="div">
					Cardholder:
				</Typography>
			</Grid>
			<Grid item xs={6} md={6} mt={1}>
				<Typography
					gutterBottom
					component="div"
					sx={{ textAlign: "right" }}
				>
					{decodeURIComponent(data?.credit_card?.card_holder)}
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography gutterBottom component="div">
					AMOUNT:
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography
					gutterBottom
					component="div"
					sx={{ textAlign: "right" }}
				>
					${toFixed(data?.total_amount)}
				</Typography>
			</Grid>
			<Grid item xs={6} md={6} mb={2}>
				<Typography gutterBottom component="div">
					Our Reference:
				</Typography>
			</Grid>
			<Grid item xs={6} md={6} mb={2}>
				<Typography
					gutterBottom
					component="div"
					sx={{ textAlign: "right" }}
				>
					{data?.reference_number}
				</Typography>
			</Grid>

			<Grid item xs={6} md={6}>
				<Typography gutterBottom component="div">
					Resp:
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography
					gutterBottom
					component="div"
					sx={{ textAlign: "right" }}
				>
					{decodeURIComponent(data?.response?.message)}
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography gutterBottom component="div">
					Code:
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography
					gutterBottom
					component="div"
					sx={{ textAlign: "right" }}
				>
					{data?.response?.auth_code}
				</Typography>
			</Grid>
			<Grid item xs={6} md={6} mb={2}>
				<Typography gutterBottom component="div">
					Ref #:
				</Typography>
			</Grid>
			<Grid item xs={6} md={6} mb={2}>
				<Typography
					gutterBottom
					component="div"
					sx={{ textAlign: "right" }}
				>
					{data?.response?.pnref}
				</Typography>
			</Grid>

			<Grid item xs={6} md={6}>
				<Typography gutterBottom component="div">
					App Name:
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography
					gutterBottom
					component="div"
					sx={{ textAlign: "right" }}
				>
					{emvData?.AppName}
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography gutterBottom component="div">
					AID:
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography
					gutterBottom
					component="div"
					sx={{ textAlign: "right" }}
				>
					{emvData?.AID}
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography gutterBottom component="div">
					TVR:
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography
					gutterBottom
					component="div"
					sx={{ textAlign: "right" }}
				>
					{emvData?.TVR}
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography gutterBottom component="div">
					TSI:
				</Typography>
			</Grid>
			<Grid item xs={6} md={6}>
				<Typography
					gutterBottom
					component="div"
					sx={{ textAlign: "right" }}
				>
					{emvData?.TSI}
				</Typography>
			</Grid>
			<Grid item xs={12} sx={{ my: 2 }}>
				<Typography
					gutterBottom
					component="div"
					variant="caption"
					sx={{ textAlign: "center" }}
				>
					Cardholder acknowledges receipt of goods and obligations set
					forth by the card holder's agreement with issuer.
				</Typography>
			</Grid>

			{data?.response?.signature && (
				<Grid
					item
					xs={12}
					sx={{
						textAlign: "center",
						my: 2,
						display: "block",
					}}
				>
					<div>
						<img
							alt={data?.credit_card?.card_holder}
							src={`data:image/bitmap;base64,${data?.response?.signature}`}
							width="60%"
							className="signature"
						/>
					</div>
				</Grid>
			)}
		</Grid>
	);
});
