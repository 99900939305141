import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";

import { permissionList } from "helpers";
import { PrivateRoute } from "components";
import Dashboard from "containers/dashboard";

import AddLocation from "containers/addLocation";
import UpdateLocation from "containers/updateLocation";
import LocationList from "containers/locationList";

import AddCompany from "containers/addCompany";
import UpdateCompany from "containers/updateCompany";
import CompanyList from "containers/companyList";

import AddTerminal from "containers/addTerminal";
import UpdateTerminal from "containers/updateTerminal";
import TerminalList from "containers/terminalList";

import NewTransaction from "containers/newTransaction";
import TransactionDetails from "containers/transactionDetails";
import TransactionList from "containers/transactionList";

import ApprovedTransactions from "containers/approvedTransactions";
import FailedTransactions from "containers/failedTransactions";
import SearchTransactions from "containers/searchTransactions";
import ExportList from "containers/exportList";
import DailySummaryReport from "containers/dailySummaryReport";
import GlobalSummaryReport from "containers/globalSummaryReport";

import AddUser from "containers/addUser";
import UpdateUser from "containers/updateUser";
import UserList from "containers/userList";

import Profile from "containers/profile";
import ChangePassword from "containers/changePassword";

import Login from "containers/login";
import PageNotFound from "containers/pageNotFound";
import SelectTerminal from "containers/selectTerminal";
import { useIsMutating } from "react-query";
import { Spinner } from "components";

let customTheme = createTheme({
	palette: {
		primary: {
			main: "#000",
			contrastText: "#fff",
		},
		secondary: {
			main: "#ab0100",
			contrastText: "#fff",
		},
	},
});

const App = () => {
	const isMutating = useIsMutating();
	return (
		<ThemeProvider theme={customTheme}>
			{isMutating > 0 && <Spinner open />}
			<Router>
				<Switch>
					<Route path="/login" component={Login} />

					<PrivateRoute exact component={Profile} path="/" />
					<Route
						exact
						path="/select-terminal"
						component={SelectTerminal}
						requiredPermissions={[permissionList.canChooseTerminal]}
					/>

					<PrivateRoute
						exact
						path="/dashboard"
						component={Dashboard}
						requiredPermissions={[permissionList.canViewDashboard]}
					/>

					{/* Admin pages */}
					<PrivateRoute
						exact
						path="/companies"
						component={CompanyList}
						requiredPermissions={[permissionList.canSetupCompanies]}
					/>
					<PrivateRoute
						exact
						path="/companies/add-company"
						component={AddCompany}
						requiredPermissions={[permissionList.canSetupCompanies]}
					/>
					<PrivateRoute
						exact
						path="/companies/:id"
						component={UpdateCompany}
						requiredPermissions={[permissionList.canSetupCompanies]}
					/>
					<PrivateRoute
						exact
						path="/locations"
						component={LocationList}
						requiredPermissions={[permissionList.canSetupLocations]}
					/>
					<PrivateRoute
						exact
						path="/locations/add-location"
						component={AddLocation}
						requiredPermissions={[permissionList.canSetupLocations]}
					/>
					<PrivateRoute
						exact
						path="/locations/:id"
						component={UpdateLocation}
						requiredPermissions={[permissionList.canSetupLocations]}
					/>
					<PrivateRoute
						exact
						path="/terminals"
						component={TerminalList}
						requiredPermissions={[permissionList.canSetupTerminals]}
					/>
					<PrivateRoute
						exact
						path="/terminals/add-terminal"
						component={AddTerminal}
						requiredPermissions={[permissionList.canSetupTerminals]}
					/>
					<PrivateRoute
						exact
						path="/terminals/:id"
						component={UpdateTerminal}
						requiredPermissions={[permissionList.canSetupTerminals]}
					/>

					{/* User pages */}
					<PrivateRoute
						exact
						path="/transactions"
						component={TransactionList}
						requiredPermissions={[
							permissionList.canRunCurrentReports,
						]}
					/>
					<PrivateRoute
						exact
						path="/transactions/new-transaction"
						component={NewTransaction}
						requiredPermissions={[
							permissionList.canRunCurrentTransactions,
						]}
					/>
					<PrivateRoute
						exact
						path="/transactions/:id"
						component={TransactionDetails}
						requiredPermissions={[
							permissionList.canRunCurrentReports,
						]}
					/>

					<PrivateRoute
						path="/reports/approved-transactions"
						component={ApprovedTransactions}
						requiredPermissions={[
							permissionList.canRunCurrentReports,
						]}
					/>
					<PrivateRoute
						exact
						path="/reports/failed-transactions"
						component={FailedTransactions}
						requiredPermissions={[
							permissionList.canRunCurrentReports,
						]}
					/>
					<PrivateRoute
						exact
						path="/reports/search-transactions"
						component={SearchTransactions}
						requiredPermissions={[
							permissionList.canRunCurrentReports,
						]}
					/>
					<PrivateRoute
						exact
						path="/reports/export-list"
						component={ExportList}
						requiredPermissions={[
							permissionList.canRunCurrentReports,
						]}
					/>
					<PrivateRoute
						path="/reports/daily-summary-report"
						component={DailySummaryReport}
						requiredPermissions={[
							permissionList.canRunCurrentReports,
						]}
					/>
					<PrivateRoute
						exact
						path="/reports/global-summary-report"
						component={GlobalSummaryReport}
						requiredPermissions={[
							permissionList.canRunCurrentReports,
						]}
					/>

					<PrivateRoute
						exact
						path="/users"
						component={UserList}
						requiredPermissions={[
							permissionList.canSetupCurrentUsers,
						]}
					/>
					<PrivateRoute
						exact
						path="/users/add-user"
						component={AddUser}
						requiredPermissions={[
							permissionList.canSetupCurrentUsers,
						]}
					/>
					<PrivateRoute
						exact
						path="/users/:id"
						component={UpdateUser}
						requiredPermissions={[
							permissionList.canSetupCurrentUsers,
						]}
					/>

					<PrivateRoute exact path="/profile" component={Profile} />
					<PrivateRoute
						exact
						path="/profile/change-password"
						component={ChangePassword}
					/>

					{/* ATTENTION!!! This route have to be the last one always. The route must be rendered only if path does not match any previous routes */}
					<PrivateRoute
						component={PageNotFound}
						pageName="Not found"
					/>
				</Switch>
			</Router>
		</ThemeProvider>
	);
};

export { App };
