import React from "react";
import ReactDOM from "react-dom";
import { App } from "./containers/app/app";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { QueryClientProvider } from "react-query";
import { queryClient } from "helpers";
// import { ReactQueryDevtools } from "react-query/devtools";
import ModalProvider from "mui-modal-provider";
import { positions, Provider as AlertProvider } from "react-alert";
import { AlertTemplate } from "components";

const alertOptions = {
	timeout: 5000,
	position: positions.BOTTOM_RIGHT,
	containerStyle: {
		bottom: "40px",
		zIndex: 1300,
	},
};

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<ModalProvider beta={true}>
				<AlertProvider template={AlertTemplate} {...alertOptions}>
					<App />
				</AlertProvider>
			</ModalProvider>
			{/* <ReactQueryDevtools initialIsOpen={false} /> */}
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
