import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { ListItemIcon, ListItemText, ListItemButton } from "@mui/material";
import { iconMenuStyle } from "helpers";

const MenuItem = ({ text, to, icon }) => {
	const match = useRouteMatch(to);
	const activeClass = match?.isExact && "active-menu";

	return (
		<ListItemButton component={NavLink} to={to} className={activeClass}>
			{icon && <ListItemIcon sx={iconMenuStyle}>{icon}</ListItemIcon>}
			<ListItemText primary={text} />
		</ListItemButton>
	);
};
export default MenuItem;
