import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
	Toolbar,
	IconButton,
	Drawer,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemButton,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import LogoutIcon from "@mui/icons-material/Logout";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RouterIcon from "@mui/icons-material/Router";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import {
	logout,
	getRoleTitle,
	permissionList,
	iconMenuStyle,
	useLocalStorage,
} from "helpers";
import MenuItem from "./menuItem";
import SubMenu from "./subMenu";
import { HasPermissions } from "components";

const SideMenu = ({ open, toggleDrawer, role }) => {
	const history = useHistory();

	const { pathname } = useLocation();
	const [activeSegment] = pathname.slice(1).split("/");

	const [userData] = useLocalStorage("userData", "");
	const [expanded, setExpanded] = useState(activeSegment);

	const handleChange = panel => (_e, newExpanded) =>
		setExpanded(newExpanded ? panel : false);

	const handleLogout = () => logout(history);

	return (
		<>
			<Drawer
				variant="permanent"
				className={`side-menu ${open && "open"}`}
				PaperProps={{
					sx: {
						backgroundColor: "primary.main",
						color: "primary.contrastText",
					},
				}}
			>
				<Toolbar
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
						px: [1],
					}}
				>
					<IconButton color="inherit" onClick={toggleDrawer}>
						<ChevronLeftIcon />
					</IconButton>
				</Toolbar>
				<Divider />
				<List>
					{/* <img
						src={`${BASE_URL}/api/companies/current/image`}
						alt="Company Logo"
						height="40px"
					/> */}
					<ListItem component="div">
						<AccountCircleIcon className="roleIcon" />
						{getRoleTitle(role)}
					</ListItem>
					<HasPermissions
						requiredPermissions={[permissionList.canViewDashboard]}
					>
						<MenuItem
							text="Dashboard"
							to="/dashboard"
							icon={<DashboardIcon />}
						/>
					</HasPermissions>
					{/* Admin pages */}
					<SubMenu
						name="Manage Company"
						sectionName="companies"
						handleChange={handleChange}
						expanded={expanded}
						icon={<BusinessIcon />}
						menu={[
							{
								to: "/companies/add-company",
								text: "New Company",
							},
							{
								to: "/companies",
								text: "Company List",
							},
						]}
						requiredPermissions={[permissionList.canSetupCompanies]}
					/>

					<SubMenu
						name="Manage Location"
						sectionName="locations"
						handleChange={handleChange}
						expanded={expanded}
						icon={<LocationOnIcon />}
						menu={[
							{
								to: "/locations/add-location",
								text: "New Location",
							},
							{
								to: "/locations",
								text: "Location List",
							},
						]}
						requiredPermissions={[permissionList.canSetupLocations]}
					/>

					<SubMenu
						name="Manage Terminal"
						sectionName="terminals"
						handleChange={handleChange}
						expanded={expanded}
						icon={<RouterIcon />}
						menu={[
							{
								to: "/terminals/add-terminal",
								text: "New Terminal",
							},
							{
								to: "/terminals",
								text: "Terminal List",
							},
						]}
						requiredPermissions={[permissionList.canSetupTerminals]}
					/>

					{/* User Pages */}
					<HasPermissions
						requiredPermissions={[
							permissionList.canRunCurrentTransactions,
							permissionList.canRunCurrentReports,
						]}
					>
						<SubMenu
							name="Manage Transactions"
							sectionName="transactions"
							handleChange={handleChange}
							expanded={expanded}
							icon={<CreditCardIcon />}
							menu={[
								{
									to: "/transactions/new-transaction",
									text: "New Transaction",
									requiredPermissions: [
										permissionList.canRunCurrentTransactions,
									],
								},
								{
									to: "/transactions",
									text: "Transaction List",
									requiredPermissions: [
										permissionList.canRunCurrentReports,
									],
								},
							]}
						/>
					</HasPermissions>
					<SubMenu
						name="Transaction Reports"
						sectionName="reports"
						handleChange={handleChange}
						expanded={expanded}
						icon={<AssessmentIcon />}
						menu={[
							{
								to: "/reports/approved-transactions",
								text: "Approved Transaction",
							},
							{
								to: "/reports/failed-transactions",
								text: "Failed Transaction",
							},
							{
								to: "/reports/search-transactions",
								text: "Search Transactions",
							},
							{
								to: "/reports/export-list",
								text: "Export List",
							},
							{
								to: "/reports/daily-summary-report",
								text: "Daily Summary Report",
							},
							{
								to: "/reports/global-summary-report",
								text: "Global Summary Report",
							},
						]}
						requiredPermissions={[
							permissionList.canRunCurrentReports,
						]}
					/>

					<SubMenu
						name="Manage Users"
						sectionName="users"
						handleChange={handleChange}
						expanded={expanded}
						icon={<PeopleIcon />}
						menu={[
							{
								to: "/users/add-user",
								text: "New User",
							},
							{
								to: "/users",
								text: "User List",
							},
						]}
						requiredPermissions={[
							permissionList.canSetupCurrentUsers,
						]}
					/>
					<SubMenu
						name="My Accounts"
						sectionName="profile"
						handleChange={handleChange}
						expanded={expanded}
						icon={<PersonIcon />}
						menu={[
							{
								to: "/profile",
								text: "Profile",
							},
							{
								to: "/profile/change-password",
								text: "Change Password",
							},
						]}
					/>
					{!userData?.terminal_id && (
						<HasPermissions
							requiredPermissions={[
								permissionList.canChooseTerminal,
							]}
						>
							<ListItemButton
								component={Link}
								to="/select-terminal"
							>
								<ListItemIcon sx={iconMenuStyle}>
									<ToggleOffIcon />
								</ListItemIcon>
								<ListItemText primary="Switch Terminal / Location" />
							</ListItemButton>
						</HasPermissions>
					)}
					<ListItemButton onClick={handleLogout}>
						<ListItemIcon sx={iconMenuStyle}>
							<LogoutIcon />
						</ListItemIcon>
						<ListItemText primary="Logout" />
					</ListItemButton>
				</List>
			</Drawer>
		</>
	);
};
export default SideMenu;
