import React from "react";
import { useForm } from "react-hook-form";
import { Container, Box, Button, Grid, Typography, Input } from "@mui/material";
import { toFixed } from "helpers";

export const TransactionReturnForm = ({ onSubmit, amount, cancel }) => {
	const { handleSubmit } = useForm({
		mode: "onChange",
		defaultValues: { return_amount: amount },
	});

	const handleOnSubmit = values => onSubmit(values.return_amount);

	return (
		<Container component="main" className="formWrapper">
			<Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
				<Typography component="h6" variant="h6" mb={2}>
					Return Transaction
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography component="p" variant="body1" mb={1}>
							Do you want to you return the amount&nbsp;
							<strong>${toFixed(amount)}</strong>?
						</Typography>
					</Grid>

					<Input
						name="return_amount"
						disabled
						defaultValue=""
						type="hidden"
					/>

					<Grid item xs={12}>
						<Button
							sx={{ mr: 3 }}
							type="submit"
							variant="contained"
							size="large"
						>
							Submit
						</Button>
						<Button
							variant="outlined"
							size="large"
							onClick={cancel}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
};
