import React from "react";
import { Button } from "@mui/material";
import { ReactHookFormInput } from "components";
import { useForm } from "react-hook-form";
import { Box } from "@mui/system";
import { useCurrentUser } from "queries";
import { emailValid, maxLength, required } from "helpers";
import { useAlert } from "react-alert";
import { DataContainer } from "components/dataContainer";

const Profile = () => {
	const alert = useAlert();
	const { useCurrentUserQuery, useUserUpdateQuery } = useCurrentUser();
	const { data, isFetching } = useCurrentUserQuery();
	const { mutateAsync: handleUpdateUser, isLoading } =
		useUserUpdateQuery(alert);

	const { handleSubmit, control } = useForm();

	const onSubmit = async ({ full_name, email }) =>
		handleUpdateUser({ ...data, full_name, email });

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<h2>My Profile</h2>
			</Box>

			<DataContainer isFetching={isFetching}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						component="form"
						onSubmit={handleSubmit(onSubmit)}
						sx={{ mt: 1, maxWidth: 700 }}
					>
						<ReactHookFormInput
							name="full_name"
							label="Full Name*"
							control={control}
							autoFocus
							size="large"
							defaultValue={data?.full_name}
							isLoading={isLoading}
							rules={{
								validate: {
									required,
									minLength: maxLength(50),
								},
							}}
						/>
						<ReactHookFormInput
							name="email"
							label="Email*"
							type="email"
							control={control}
							size="large"
							isLoading={isLoading}
							defaultValue={data?.email}
							rules={{
								validate: {
									required,
									minLength: maxLength(75),
									email: emailValid,
								},
							}}
						/>
						<Button
							type="submit"
							variant="contained"
							size="large"
							sx={{ mt: 3, mb: 2 }}
						>
							Update
						</Button>
					</Box>
				</Box>
			</DataContainer>
		</>
	);
};
export default Profile;
