import React from "react";
import { SelectTerminalForm } from "components";
import { Container } from "@mui/material";
import { useHistory } from "react-router";

const SelectTerminal = () => {
	const history = useHistory();
	const onSubmitForm = () => history.push("/transactions/new-transaction");
	return (
		<Container
			maxWidth="xs"
			sx={{
				mt: 8,
				border: "1px solid rgba(0, 0, 0, 0.12)",
				px: "0 !important",
				pt: 2,
				boxShadow: 5,
			}}
		>
			<SelectTerminalForm onSubmitForm={onSubmitForm} />
		</Container>
	);
};
export default SelectTerminal;
