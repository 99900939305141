import React from "react";
import { Box, Typography } from "@mui/material";

const Footer = () => {
	return (
		<Box
			component="footer"
			className="footer"
			sx={{
				backgroundColor: "primary.main",
				color: "primary.contrastText",
			}}
		>
			<Typography variant="p">
				© {new Date().getFullYear()} All rights reserved.
			</Typography>
		</Box>
	);
};
export default Footer;
