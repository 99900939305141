import React from "react";
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { DataContainer } from "components";

export const TotalCard = ({
	to = "",
	count = 0,
	title = "Today's Approved Transactions",
	theme = "primary",
	color = "#fff",
	isFetching,
}) => {
	return (
		<Card sx={{ background: theme }}>
			<CardContent>
				<Typography sx={{ fontSize: 16 }} color={color} gutterBottom>
					{title}
				</Typography>
				<DataContainer isFetching={isFetching} height={30}>
					<Typography variant="h3" component="div" color={color}>
						{count}
					</Typography>
				</DataContainer>
			</CardContent>
			<CardActions sx={{ borderTop: `1px solid ${color}` }}>
				<Button component={Link} to={to} sx={{ color: "#fff" }}>
					More info
				</Button>
			</CardActions>
		</Card>
	);
};
