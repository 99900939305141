import { useState } from "react";
import { queryClient } from "helpers";
import { useMutation, useQuery } from "react-query";
import { terminalService } from "services";

export const useTerminal = () => {
	const [page, setPage] = useState(1);

	const useTerminalsListQuery = () => {
		const fetch = () =>
			terminalService.getTerminals({
				page,
			});

		const queryResult = useQuery(["terminalsList", page], fetch, {
			keepPreviousData: true,
			staleTime: 5000,
			retry: false,
		});

		return { ...queryResult, page, setPage };
	};

	const useTerminalDeleteQuery = (alert, onSuccess) => {
		return useMutation(id => terminalService.deleteTerminal(id), {
			onSuccess() {
				alert.success("Terminal has been deleted successfully.");
				onSuccess?.();
			},
			onError(error) {
				alert.error(error);
			},
		});
	};

	const useGetTerminalQuery = id => {
		const fetch = () => terminalService.getTerminalData(id);

		const queryResult = useQuery(["terminal", id], fetch, {
			enabled: !!id,
			retry: false,
		});

		return { ...queryResult };
	};

	const useAddTerminalQuery = alert => {
		return useMutation(values => terminalService.addTerminal(values), {
			async onSuccess() {
				alert.success("Terminal has been added successfully.");
				await queryClient.refetchQueries(["terminalsList", page]);
			},
			onError(error) {
				alert.error(error);
			},
		});
	};

	const useUpdateTerminalQuery = (id, alert) => {
		return useMutation(
			values => terminalService.updateTerminal(values, id),
			{
				onSuccess() {
					alert.success("Terminal has been updated successfully.");
				},
				onError(error) {
					alert.error(error);
				},
			},
		);
	};

	return {
		useTerminalsListQuery,
		useTerminalDeleteQuery,
		useAddTerminalQuery,
		useUpdateTerminalQuery,
		useGetTerminalQuery,
	};
};
