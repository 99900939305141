import moment from "moment";
import { axiosWithHandler, hasPermissions, permissionList } from "helpers";

export const transactionService = {
	getTransactions,
	getTransaction,
	addTransaction,
	refreshTransaction,
	voidTransaction,
	returnTransaction,
	getTransactionsCsv,
	getRelatedTransactions,
	getSummaryReport,
};

const PAGE_SIZE = 40;

function getTransactions({ page, ...data }) {
	const params = { ...data };
	const { id } = JSON.parse(localStorage.getItem("userData")) || {};
	params.from = params?.from && moment(params?.from).format("YYYY-MM-DD");
	params.to =
		(params?.to &&
			moment(params?.to).add(1, "days").format("YYYY-MM-DD")) ||
		(!params?.to &&
			params?.from &&
			moment(params?.from).add(1, "days").format("YYYY-MM-DD"));

	params.user_id = !hasPermissions([permissionList.canViewCurrentUsers])
		? id
		: params.user_id;

	for (const key of Object.keys(params)) {
		if (
			params[key] === "" ||
			params[key] === null ||
			params[key] === undefined
		) {
			delete params[key];
		}
	}

	const options = {
		method: "get",
		url: "/api/companies/current/transactions",
		params: {
			page_index: page - 1,
			page_size: PAGE_SIZE,
			...params,
		},
	};

	return axiosWithHandler(options);
}

function getTransactionsCsv(data) {
	const params = { ...data };
	const { id } = JSON.parse(localStorage.getItem("userData")) || {};
	params.from = params?.from && moment(params?.from).format("YYYY-MM-DD");
	params.to =
		(params?.to &&
			moment(params?.to).add(1, "days").format("YYYY-MM-DD")) ||
		(!params?.to &&
			params?.from &&
			moment(params?.from).add(1, "days").format("YYYY-MM-DD"));
	params.user_id = !hasPermissions([permissionList.canViewCurrentUsers])
		? id
		: null;

	for (const key of Object.keys(params)) {
		if (
			params[key] === "" ||
			params[key] === null ||
			params[key] === undefined
		) {
			delete params[key];
		}
	}

	const options = {
		method: "get",
		url: "/api/companies/current/transactions/csv",
		params,
	};

	return axiosWithHandler(options);
}

function getTransaction(id) {
	const options = {
		method: "get",
		url: `/api/companies/current/transactions/${id}`,
	};

	return axiosWithHandler(options);
}

function getRelatedTransactions(id) {
	const options = {
		method: "get",
		url: `/api/companies/current/transactions/${id}/related_transactions`,
	};

	return axiosWithHandler(options);
}

function addTransaction({ terminal_id, ...data }) {
	const options = {
		method: "post",
		url: `/api/companies/current/terminals/${terminal_id}/sale`,
		data,
	};

	return axiosWithHandler(options);
}

function refreshTransaction(id) {
	const options = {
		method: "post",
		url: `/api/companies/current/transactions/${id}/refresh`,
	};

	return axiosWithHandler(options);
}

function voidTransaction(id, is_signature_required) {
	const options = {
		method: "post",
		url: `/api/companies/current/transactions/${id}/void`,
		data: {
			is_signature_required,
		},
	};

	return axiosWithHandler(options);
}

function returnTransaction(id, return_amount) {
	const options = {
		method: "post",
		url: `/api/companies/current/transactions/${id}/return`,
		data: {
			return_amount,
		},
	};

	return axiosWithHandler(options);
}

function getSummaryReport(data) {
	const params = { ...data };

	params.from = params?.from && moment(params?.from).format("YYYY-MM-DD");
	params.to =
		(params?.to &&
			moment(params?.to).add(1, "days").format("YYYY-MM-DD")) ||
		(!params?.to &&
			params?.from &&
			moment(params?.from).add(1, "days").format("YYYY-MM-DD"));

	for (const key of Object.keys(params)) {
		if (
			params[key] === "" ||
			params[key] === null ||
			params[key] === undefined
		) {
			delete params[key];
		}
	}
	const options = {
		method: "get",
		url: "/api/companies/current/transactions/report",
		params,
	};

	return axiosWithHandler(options);
}
