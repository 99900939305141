import React from "react";
import { Typography, Toolbar, IconButton, AppBar, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import HeaderMenu from "./headerMenu";
import { HasPermissions } from "components";
import Logo from "static/images/logo.svg";
import { permissionList, useLocalStorage } from "helpers";

const Header = ({ open, toggleDrawer, userName, role }) => {
	const [terminal] = useLocalStorage("terminal", "");

	return (
		<AppBar
			position="absolute"
			color="secondary"
			className={`header ${open && "open"}`}
		>
			<Toolbar
				sx={{
					pr: "24px",
					justifyContent: "space-between",
				}}
			>
				<IconButton
					edge="start"
					color="inherit"
					aria-label="open drawer"
					onClick={toggleDrawer}
					sx={{
						marginRight: "36px",
						...(open && { display: "none" }),
					}}
				>
					<MenuIcon />
				</IconButton>
				<Box sx={{ mr: "auto", mt: "5px", mb: "5px" }}>
					<img src={Logo} alt="Chosen Payment" height="50px" />
				</Box>
				<HasPermissions
					requiredPermissions={[
						permissionList.canChooseTerminal,
					]}
				>
					<Typography
						component="p"
						color="inherit"
						noWrap
						sx={{ flexGrow: 1, textAlign: "center" }}
					>
						You are on Terminal: {terminal?.terminal_name}
					</Typography>
				</HasPermissions>

				<HeaderMenu userName={userName} />
			</Toolbar>
		</AppBar>
	);
};
export default Header;
