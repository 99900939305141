import React from "react";
import { Alert } from "@mui/material";

export const AlertTemplate = ({ message, options, close }) => {
	return (
		<Alert severity={options.type} onClose={close} sx={{ margin: 1 }}>
			{message}
		</Alert>
	);
};
