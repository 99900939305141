import React, { useCallback, useRef, useState } from "react";
import {
	Button,
	Box,
	Grid,
	Divider,
	IconButton,
	LinearProgress,
	Typography,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { useTransaction } from "queries";
import { useAlert } from "react-alert";
import { useParams } from "react-router";
import { DataContainer, TransactionReceipt } from "components";

import { Content } from "./content";
import { Actions } from "./actions";
import { toFixed, transactionStatuses, transactionTypes } from "helpers";

const TransactionDetails = () => {
	const alert = useAlert();
	const { id } = useParams();
	const componentRef = useRef();
	const [action, setAction] = useState("");

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const {
		useTransactionQuery,
		useVoidTransactionQuery,
		useReturnTransactionQuery,
		useRelatedTransactionsQuery,
	} = useTransaction();
	const { data, isFetching } = useTransactionQuery(id);
	const {
		data: relatedTransactions,
		isFetching: isFetchingRelatedTransactions,
		refetch: refetchRelatedTransactions,
	} = useRelatedTransactionsQuery(id);

	const returnTransactions =
		relatedTransactions?.filter(
			item =>
				item.type === transactionTypes.RETURN &&
				item.status === transactionStatuses.APPROVED,
		) || [];
	const voidTransactions =
		relatedTransactions?.filter(
			item =>
				item.type === transactionTypes.VOID &&
				item.status === transactionStatuses.APPROVED,
		) || [];
	const returnedAmount = returnTransactions.reduce(
		(acc, cur) => acc + cur.total_amount,
		0,
	);
	const isVoid = voidTransactions.length > 0;
	const isReturn = returnTransactions.length > 0;
	const isTypeSale = data?.type === transactionTypes.SALE;
	const isApproved = data?.status === transactionStatuses.APPROVED;
	const possibleReturn = toFixed(data?.total_amount - returnedAmount);

	const onSuccess = useCallback(() => {
		setAction("");
		refetchRelatedTransactions();
	}, [refetchRelatedTransactions]);

	const { mutateAsync: handleVoidTransaction } = useVoidTransactionQuery(
		alert,
		onSuccess,
	);
	const { mutateAsync: handleReturnTransaction } = useReturnTransactionQuery(
		alert,
		onSuccess,
	);

	const handleVoidClick = () => setAction("void");
	const handleReturnClick = () => setAction("return");
	const handlePartialReturnClick = () => setAction("partialReturn");
	const handleShowHistoryClick = () => setAction("history");
	const handleCancelClick = () => setAction("");
	const handleVoid = useCallback(
		is_signature_required =>
			handleVoidTransaction({ id, is_signature_required }),
		[handleVoidTransaction, id],
	);
	const handleReturn = useCallback(
		return_amount => handleReturnTransaction({ id, return_amount }),
		[handleReturnTransaction, id],
	);

	const isActionsShow =
		data &&
		!isFetching &&
		!isVoid &&
		isTypeSale &&
		isApproved &&
		possibleReturn > 0;
	const isHistoryShow = relatedTransactions?.length > 0;
	const isReceiptShow = isApproved;
	const isHistoryShown = action === "history";

	return (
		<Grid container spacing={4}>
			<Grid item xs={12} md={7} lg={8} xl={9}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						flexWrap: "wrap",
						mb: 2,
						mt: 2,
					}}
				>
					<Typography variant="div" component="h2" mb={1}>
						Transaction Details
					</Typography>
					{isFetchingRelatedTransactions ? (
						<Box sx={{ width: 150 }}>
							<LinearProgress />
						</Box>
					) : (
						<Box sx={{ display: "flex", flexWrap: "nowrap" }}>
							{isActionsShow && (
								<Actions
									handleVoidClick={handleVoidClick}
									handleReturnClick={handleReturnClick}
									handlePartialReturnClick={
										handlePartialReturnClick
									}
									isReturn={isReturn}
								/>
							)}
							{isHistoryShow && !isHistoryShown && (
								<Button
									variant="outlined"
									size="medium"
									onClick={handleShowHistoryClick}
									sx={{ ml: 2 }}
								>
									Show History
								</Button>
							)}
							{isHistoryShown && (
								<Button
									variant="outlined"
									size="medium"
									onClick={handleCancelClick}
									sx={{ ml: 2 }}
								>
									Hide History
								</Button>
							)}
						</Box>
					)}
				</Box>

				<DataContainer isFetching={isFetching} isData={!!data}>
					<Content
						action={action}
						data={data}
						handleCancelClick={handleCancelClick}
						handleVoid={handleVoid}
						handleReturn={handleReturn}
						returnedAmount={returnedAmount}
						possibleReturn={possibleReturn}
						transactions={relatedTransactions}
					/>
				</DataContainer>
			</Grid>
			{isReceiptShow && (
				<Grid
					item
					xs={12}
					md={5}
					lg={4}
					xl={3}
					sx={{ maxWidth: "400px !important" }}
				>
					<div className="receiptWrapper">
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<h2>Transaction Receipt</h2>

							<IconButton
								onClick={handlePrint}
								color="primary"
								title="Print"
							>
								<LocalPrintshopIcon />
							</IconButton>
						</Box>

						<Divider />

						<DataContainer isFetching={isFetching} isData={!!data}>
							<TransactionReceipt
								data={data}
								ref={componentRef}
							/>
						</DataContainer>
					</div>
				</Grid>
			)}
		</Grid>
	);
};
export default TransactionDetails;
