import { useState } from "react";
import { useQuery } from "react-query";
import { currentLocationService } from "services";

export const useCurrentLocation = () => {
	const [page, setPage] = useState(1);

	const useLocationsListQuery = () => {
		const fetch = () =>
			currentLocationService.getLocations({
				page,
			});

		const queryResult = useQuery(["currentLocationsList", page], fetch, {
			keepPreviousData: true,
			staleTime: 5000,
			retry: false,
		});

		return { ...queryResult, page, setPage };
	};

	const useLocationsSelectQuery = () => {
		const fetch = () =>
			currentLocationService.getLocations({
				page: 1,
				page_size: 500,
			});

		const queryResult = useQuery("currentLocationsDropdown", fetch, {
			retry: false,
			select: ({ items }) =>
				items.map(({ id, name }) => ({
					value: id,
					name,
				})),
		});

		return { ...queryResult };
	};

	return {
		useLocationsListQuery,
		useLocationsSelectQuery,
	};
};
