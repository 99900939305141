import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableRow,
} from "@mui/material";
import { toFixed } from "helpers";

const UsersList = ({ data = {} }) => {
	return (
		<Table size="small">
			<TableHead>
				<TableRow>
					<TableCell>User ID</TableCell>
					<TableCell>Count</TableCell>
					<TableCell align="right">Amount, $</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{data?.users?.map(row => (
					<TableRow key={row.user_id}>
						<TableCell component="th" scope="row">
							{row.username}
						</TableCell>
						<TableCell>{row.transaction_count}</TableCell>
						<TableCell align="right">
							{toFixed(row.transaction_amount)}
						</TableCell>
					</TableRow>
				))}
			</TableBody>
			<TableFooter>
				<TableRow>
					<TableCell>Overall Totals</TableCell>
					<TableCell>Count: {data?.transaction_count}</TableCell>
					<TableCell align="right">
						Amount: ${toFixed(data?.transaction_amount)}
					</TableCell>
				</TableRow>
			</TableFooter>
		</Table>
	);
};
export default UsersList;
