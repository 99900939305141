import React from "react";
import { UserForm, DataContainer } from "components";
import { useAlert } from "react-alert";
import { useCurrentTerminal, useCurrentUser } from "queries";

const AddUser = () => {
	const alert = useAlert();
	const { useAddUserQuery } = useCurrentUser();
	const { mutateAsync: handleAddUser } = useAddUserQuery(alert);

	const { useTerminalsSelectQuery } = useCurrentTerminal();
	const { data: terminals = [], isFetching: isTerminalsFetching } =
		useTerminalsSelectQuery();

	const terminalOptions = [{ value: "all", name: "All" }, ...terminals] || [];

	const initialValues = {
		terminal_id: "all",
		username: "",
		password: "",
		full_name: "",
		email: "",
		role: "",
		is_allow_void: "no",
		is_allow_return: "no",
	};
	return (
		<>
			<h2> Create a new user</h2>
			<DataContainer isFetching={isTerminalsFetching}>
				<UserForm
					onSubmit={handleAddUser}
					data={initialValues}
					terminals={terminalOptions}
				/>
			</DataContainer>
		</>
	);
};
export default AddUser;
