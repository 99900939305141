import React from "react";
import { CompanyForm } from "components";
import { useAlert } from "react-alert";
import { useCompany, useUser } from "queries";
import { useParams } from "react-router";

const UpdateCompany = () => {
	const { id } = useParams();
	const alert = useAlert();
	const { useUpdateCompanyQuery, useAddCompanyImageQuery } = useCompany();
	const { useUpdateUserQuery } = useUser();
	const { mutateAsync: handleUpdateCompany } = useUpdateCompanyQuery(
		id,
		alert,
	);
	const { mutateAsync: handleAddCompanyImage } =
		useAddCompanyImageQuery(alert);
	const { mutateAsync: handleUpdateUser } = useUpdateUserQuery(alert);

	const handleOnSubmit = async (values, selectedFile) => {
		await handleUpdateCompany(values);
		selectedFile && handleAddCompanyImage({ id, file: selectedFile });
		handleUpdateUser(values);
	};

	return (
		<>
			<h2>Update company</h2>
			<CompanyForm onSubmit={handleOnSubmit} id={id} />
		</>
	);
};
export default UpdateCompany;
