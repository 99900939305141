import React, { useCallback, useMemo, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { CustomTable, TransactionReceipt } from "components";
import { getDate, toUpperCase, TRANSACTION_STATUS } from "helpers";

import { Typography, Box, Portal } from "@mui/material";
import { useTransaction } from "queries";
import { useAlert } from "react-alert";
import { Actions } from "./actions";

export const Transactions = ({
	transactions,
	isLoading = false,
	count,
	page,
	handlePageChange,
	handleSortChange,
}) => {
	const alert = useAlert();
	const componentRef = useRef();
	const [data, setData] = useState({});

	const { useRefreshTransactionQuery } = useTransaction();
	const { mutateAsync: refresh } = useRefreshTransactionQuery(alert);

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const handleClickPrint = useCallback(
		async data => {
			await setData(data);
			handlePrint();
		},
		[handlePrint],
	);

	const columns = useMemo(
		() => [
			{
				Header: "ID",
				accessor: "id",
			},
			{
				Header: "Reference",
				accessor: "reference_number",
			},
			{
				Header: "Amount",
				accessor: "total_amount",
				Cell: ({ value }) => value && `$${value.toFixed(2)}`,
				align: "right",
			},
			{
				Header: "Transaction Type",
				accessor: "type",
				align: "center",
				Cell: ({ value }) => value && toUpperCase(value),
			},
			{
				Header: "User Name",
				accessor: "user",
				disableSortBy: true,
				Cell: ({ value }) => value?.username,
			},
			{
				Header: "Register ID",
				accessor: "terminal_note",
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: ({ value }) => {
					return (
						value &&
						TRANSACTION_STATUS.map((item, index) =>
							item.value === value ? (
								<Typography
									fontWeight="500"
									key={index}
									color={item.color}
								>
									{item.name}
								</Typography>
							) : (
								""
							),
						)
					);
				},
			},
			{
				Header: "Transaction Date",
				accessor: "created_at",
				Cell: ({ value }) => getDate(value),
			},
			{
				Header: "Options",
				id: "options",
				disableSortBy: true,
				disableOnPrint: true,
				Cell: ({ row }) => (
					<Actions
						data={row.original}
						print={handleClickPrint}
						refresh={refresh}
					/>
				),
			},
		],
		[handleClickPrint, refresh],
	);

	return (
		<>
			<CustomTable
				columns={columns}
				data={transactions}
				isLoading={isLoading}
				handlePageChange={handlePageChange}
				count={count}
				pageIndex={page}
				hover
				stickyHeader
				handleSortChange={handleSortChange}
				initialSortBy={[
					{
						id: "id",
						desc: true,
					},
				]}
			/>
			<Portal>
				<Box component="div" sx={{ display: "none" }}>
					<TransactionReceipt data={data} ref={componentRef} />
				</Box>
			</Portal>
		</>
	);
};
