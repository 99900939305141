import React from "react";
import { useForm } from "react-hook-form";
import { Container, Box, Button, Grid } from "@mui/material";

import { ReactHookFormInput, ReactHookFormSelect } from "components";
import {
	emailValid,
	minLength,
	required,
	ROLES_VALUES,
	SWITCHER_VALUES,
	maxLength,
} from "helpers";
import { useHistory } from "react-router";

export const UserForm = ({ onSubmit, update, data = {}, terminals }) => {
	const history = useHistory();

	const { handleSubmit, control, reset } = useForm({
		defaultValues: { ...data, password: "" },
	});

	const toListPage = () => history.push("/users");

	const handleOnSubmit = async values => {
		await onSubmit(values);
		toListPage();
	};

	const handleOnSubmitAndMore = async values => {
		await onSubmit(values);
		reset(data);
	};

	return (
		<Container
			component="main"
			className="formWrapper formWrapperOneColumn"
		>
			<Box
				component="form"
				onSubmit={handleSubmit(handleOnSubmit)}
				sx={{ mb: 3 }}
			>
				<Grid container rowSpacing={1} columnSpacing={5}>
					<Grid item xs={12}>
						<ReactHookFormSelect
							name="terminal_id"
							label="Select Terminal*"
							control={control}
							size="small"
							variant="outlined"
							options={terminals}
						/>
					</Grid>
					<Grid item xs={12}>
						<ReactHookFormInput
							name="username"
							label="Username*"
							control={control}
							size="small"
							rules={{
								validate: {
									required,
									minLength: minLength(3),
								},
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<ReactHookFormInput
							name="password"
							label="User Password*"
							control={control}
							size="small"
							rules={
								update
									? {
											validate: {
												minLength: minLength(5),
												maxLength: maxLength(50),
											},
									  }
									: {
											validate: {
												required,
												minLength: minLength(5),
												maxLength: maxLength(50),
											},
									  }
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<ReactHookFormInput
							name="full_name"
							label="User Fullname*"
							control={control}
							size="small"
							rules={{
								validate: {
									required,
									minLength: minLength(3),
								},
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<ReactHookFormInput
							name="email"
							label="User Email*"
							control={control}
							size="small"
							rules={{
								validate: {
									required,
									email: emailValid,
								},
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<ReactHookFormSelect
							name="role"
							label="User Type*"
							control={control}
							size="small"
							variant="outlined"
							options={ROLES_VALUES}
							rules={{ validate: { required } }}
						/>
					</Grid>
					<Grid item xs={12}>
						<ReactHookFormSelect
							name="is_allow_void"
							label="Allow Void*"
							control={control}
							size="small"
							variant="outlined"
							options={SWITCHER_VALUES}
							rules={{ validate: { required } }}
						/>
					</Grid>
					<Grid item xs={12}>
						<ReactHookFormSelect
							name="is_allow_return"
							label="Allow Return*"
							control={control}
							size="small"
							variant="outlined"
							options={SWITCHER_VALUES}
							rules={{ validate: { required } }}
						/>
					</Grid>

					<Grid item xs={12} mt={2}>
						{update ? (
							<Button
								type="submit"
								variant="contained"
								size="large"
								sx={{ mr: 3 }}
							>
								Update User
							</Button>
						) : (
							<>
								<Button
									type="submit"
									variant="contained"
									size="large"
									sx={{ mr: 3 }}
								>
									Add User
								</Button>
								<Button
									variant="contained"
									size="large"
									sx={{ mr: 3 }}
									onClick={handleSubmit(
										handleOnSubmitAndMore,
									)}
								>
									Add User and More
								</Button>
							</>
						)}
						<Button
							variant="outlined"
							size="large"
							onClick={toListPage}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
};
