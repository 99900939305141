import React, { useState } from "react";
import { Container, Box, Toolbar } from "@mui/material";

import Header from "./header";
import SideMenu from "./sideMenu/index";
import Footer from "./footer";
import { useLocalStorage } from "helpers";

const PrivateLayout = ({ body }) => {
	const [userData] = useLocalStorage("userData", "");

	const [open, setOpen] = useState(true);
	const toggleDrawer = () => {
		setOpen(!open);
	};

	return (
		<Box sx={{ display: "flex" }}>
			<SideMenu
				toggleDrawer={toggleDrawer}
				open={open}
				role={userData?.role}
			/>

			<Header
				toggleDrawer={toggleDrawer}
				open={open}
				userName={userData?.full_name}
				role={userData?.role}
				sx={{ display: "block", displayPrint: "none" }}
			/>

			<Box
				component="main"
				className="main-wrapper"
				sx={{
					flexGrow: 1,
					height: "100vh",
					overflow: "auto",
				}}
			>
				<Toolbar />
				<Container className="main-container" maxWidth={false}>
					{body()}
				</Container>
				<Footer />
			</Box>
		</Box>
	);
};
export default PrivateLayout;
