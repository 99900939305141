import React from "react";
import { TerminalForm } from "components";
import { useAlert } from "react-alert";
import { useTerminal } from "queries";
import { useParams } from "react-router";

const UpdateTerminal = () => {
	const { id } = useParams();
	const alert = useAlert();
	const { useUpdateTerminalQuery } = useTerminal();
	const { mutateAsync: handleUpdateTerminal } = useUpdateTerminalQuery(
		id,
		alert,
	);

	return (
		<>
			<h2>Update terminal</h2>
			<TerminalForm onSubmit={handleUpdateTerminal} id={id} />
		</>
	);
};
export default UpdateTerminal;
