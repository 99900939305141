import React from "react";
import { useForm } from "react-hook-form";
import { Container, Box, Button, Grid, Typography, Input } from "@mui/material";
import { toFixed, required } from "helpers";
import { ReactHookFormCurrencyInput } from "./reactHookFormCurrencyInput";

export const TransactionPartialReturnForm = ({
	onSubmit,
	amount,
	cancel,
	returnedAmount,
	possibleReturn,
}) => {
	const { handleSubmit, control } = useForm({
		mode: "onChange",
		defaultValues: {
			return_amount: {
				floatValue: possibleReturn,
				formattedValue: possibleReturn,
				value: possibleReturn,
			},
		},
	});

	const handleOnSubmit = values => onSubmit(values.return_amount?.value);

	return (
		<Container component="main" className="formWrapper">
			<Box
				component="form"
				noValidate
				onSubmit={handleSubmit(handleOnSubmit)}
			>
				<Typography component="h6" variant="h6" mb={2}>
					Partial Return Transaction
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography component="p" variant="body1" mb={1}>
							Total Sale Amount: ${toFixed(amount)}
						</Typography>
						<Typography component="p" variant="body1" mb={1}>
							Total Returned Amount: ${toFixed(returnedAmount)}
						</Typography>
						<Typography component="p" variant="body1">
							Do you want to you partial return? You can return up
							to ${toFixed(possibleReturn)}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<ReactHookFormCurrencyInput
							name="return_amount"
							label="Enter the amount, $"
							control={control}
							size="small"
							sx={{ maxWidth: 350 }}
							rules={{
								validate: { required },
							}}
						/>
					</Grid>

					<Input
						name="return_amount"
						disabled
						defaultValue=""
						type="hidden"
					/>

					<Grid item xs={12}>
						<Button
							sx={{ mr: 3 }}
							type="submit"
							variant="contained"
							size="large"
						>
							Submit
						</Button>
						<Button
							variant="outlined"
							size="large"
							onClick={cancel}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
};
