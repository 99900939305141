import React, { useCallback } from "react";
import { TransactionForm, DuplicateTransactionModal } from "components";
import { useTransaction } from "queries";
import { useAlert } from "react-alert";
import { useModal } from "mui-modal-provider";
import { useHistory } from "react-router";

const NewTransaction = () => {
	const alert = useAlert();
	const history = useHistory();

	const { showModal } = useModal();

	const { useAddTransactionQuery, useDuplicateTransactionQuery } =
		useTransaction();
	const { mutateAsync: addTransaction } = useAddTransactionQuery(
		alert,
		history,
	);
	const { mutateAsync: getDuplicateTransactions } =
		useDuplicateTransactionQuery();

	const showDuplicateModal = useCallback(
		(values, data) => {
			const modal = showModal(DuplicateTransactionModal, {
				onConfirm: async () => {
					modal.hide();
					await addTransaction(values);
					alert.success(
						"We have logged this possible duplicate transaction for further review.",
					);
				},
				onCancel: () => modal.hide(),
				data,
			});
		},
		[addTransaction, alert, showModal],
	);

	const handleSubmit = useCallback(
		async values => {
			const duplicateTransactions = await getDuplicateTransactions({
				reference_number: values?.reference_number,
				actual_amount: values?.actual_amount,
			});
			duplicateTransactions?.total_count
				? showDuplicateModal(values, duplicateTransactions?.items?.[0])
				: addTransaction(values);
		},
		[addTransaction, getDuplicateTransactions, showDuplicateModal],
	);

	return (
		<>
			<h2>Create a new transaction</h2>
			<TransactionForm onSubmit={handleSubmit} />
		</>
	);
};
export default NewTransaction;
