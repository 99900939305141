import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import { CustomTable } from "components";
import { getDate, toUpperCase, TRANSACTION_STATUS } from "helpers";

export const TransactionReportList = ({
	transactions,
	isLoading = false,
	count,
	page,
}) => {
	const columns = useMemo(
		() => [
			{
				Header: "ID",
				accessor: "id",
				disableSortBy: true,
			},
			{
				Header: "Reference",
				accessor: "reference_number",
				disableSortBy: true,
			},

			{
				Header: "Transaction Type",
				accessor: "type",
				align: "center",
				disableSortBy: true,
				Cell: ({ value }) => value && toUpperCase(value),
			},
			{
				Header: "User Name",
				accessor: "user",
				disableSortBy: true,
				Cell: ({ value }) => value?.username,
			},
			{
				Header: "Register ID",
				accessor: "terminal_note",
				disableSortBy: true,
			},
			{
				Header: "Status",
				accessor: "status",
				disableSortBy: true,
				Cell: ({ value }) => {
					return (
						value &&
						TRANSACTION_STATUS.map((item, index) =>
							item.value === value ? (
								<Typography
									fontWeight="500"
									key={index}
									color={item.color}
								>
									{item.name}
								</Typography>
							) : (
								""
							),
						)
					);
				},
			},
			{
				Header: "Transaction Date",
				accessor: "created_at",
				disableSortBy: true,
				Cell: ({ value }) => getDate(value),
			},
			{
				Header: "Amount",
				accessor: "total_amount",
				disableSortBy: true,
				Cell: ({ value }) => value && `$${value.toFixed(2)}`,
				align: "right",
			},
		],
		[],
	);

	return (
		<CustomTable
			columns={columns}
			data={transactions}
			isLoading={isLoading}
			count={count}
			pageIndex={page}
			hover
			stickyHeader
			hidePagination
		/>
	);
};
