import React from "react";
import { useForm } from "react-hook-form";
import { Container, Box, Button, Grid } from "@mui/material";
import {
	ReactHookFormSelect,
	ReactHookFormDatePicker,
	HasPermissions,
} from "components";
import { permissionList, TRANSACTION_STATUS } from "helpers";

export const TransactionFilter = ({
	onSubmit,
	withoutStatus,
	defaultValues,
	users,
}) => {
	const { handleSubmit, control, reset } = useForm({
		defaultValues,
	});

	const handleClearFilter = () => {
		reset();
		onSubmit(defaultValues);
	};

	return (
		<Container
			className="filterWrapper"
			sx={{ display: "block", displayPrint: "none" }}
		>
			<Box component="form" onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					rowSpacing={1}
					columnSpacing={2}
					sx={{ flexWrap: "nowrap" }}
				>
					<HasPermissions
						requiredPermissions={[
							permissionList.canViewCurrentUsers,
						]}
					>
						<Grid item xs={12} md={3}>
							<ReactHookFormSelect
								name="user_id"
								label="Select User"
								control={control}
								defaultValue=""
								size="small"
								variant="outlined"
								options={users}
								margin="dense"
							/>
						</Grid>
					</HasPermissions>
					<Grid item xs={12} md={2}>
						<ReactHookFormDatePicker
							control={control}
							label="Transaction Date"
							name="from"
							size="small"
							defaultValue=""
							margin="dense"
						/>
					</Grid>
					{!withoutStatus && (
						<Grid item xs={12} md={3}>
							<ReactHookFormSelect
								name="status"
								label="Select Transaction Status"
								defaultValue=""
								control={control}
								size="small"
								variant="outlined"
								options={[
									{
										name: "Select Transaction Status",
										value: "",
									},
									...TRANSACTION_STATUS,
								]}
								margin="dense"
							/>
						</Grid>
					)}

					<Grid item xs={12} md={4}>
						<Button
							type="submit"
							variant="contained"
							size="large"
							sx={{ mr: 2, mt: "7px", height: "40px" }}
						>
							Filter
						</Button>
						<Button
							onClick={handleSubmit(handleClearFilter)}
							variant="outlined"
							size="large"
							sx={{ mt: "7px", height: "40px" }}
						>
							Clear Filter
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
};
