import PageNotFound from "containers/pageNotFound";
import { hasPermissions, useLocalStorage } from "helpers";
import React from "react";
import { Route, Redirect } from "react-router-dom";
import PrivateLayout from "./privateLayout";

export const PrivateRoute = ({
	component: Component,
	roles,
	requiredPermissions,
	...rest
}) => {
	const [currentUser] = useLocalStorage("currentUser", "");
	return (
		<Route
			{...rest}
			render={props => {
				if (!currentUser) {
					// not logged in so redirect to login page with the return url
					return <Redirect to={{ pathname: "/login" }} />;
				}
				// authorised so return component
				if (hasPermissions(requiredPermissions)) {
					return (
						<PrivateLayout
							{...props}
							body={() => <Component {...props} />}
						/>
					);
				}
				return (
					<PrivateLayout {...props} body={() => <PageNotFound />} />
				);
			}}
		/>
	);
};
