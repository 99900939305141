import React from "react";
import moment from "moment";
import PrintIcon from "@mui/icons-material/Print";
import {
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { toFixed } from "helpers";

const ReportHeader = ({ data = {}, companyIName = "", date = "" }) => {
	const grandTotal = data.reduce(
		(sum, cur) => sum + cur?.transaction_amount,
		0,
	);
	return (
		<Box sx={{ mb: 7 }}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					mb: 3,
				}}
			>
				<Typography variant="subtitle2" sx={{ pl: 2 }}>
					Transaction Date:&nbsp;
					<Typography
						component="span"
						sx={{ textTransform: "uppercase" }}
					>
						{date && moment(date).format("DD-MMM-YYYY")}
					</Typography>
				</Typography>
				<Box sx={{ display: "block", displayPrint: "none" }}>
					<Button
						variant="outlined"
						size="small"
						onClick={window.print}
						startIcon={<PrintIcon />}
					>
						Print
					</Button>
				</Box>
			</Box>
			<Table size="small" className="borderless">
				<TableHead>
					<TableRow>
						<TableCell>Merchant ID</TableCell>
						<TableCell>Business Name</TableCell>
						<TableCell align="right">Total Settled, $</TableCell>
						<TableCell>Settle Date</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map(row => (
						<TableRow key={row.location_id}>
							<TableCell component="th" scope="row">
								{row.location_id}
							</TableCell>
							<TableCell>{`${companyIName} ${row.location_name}`}</TableCell>
							<TableCell align="right">
								{toFixed(row.transaction_amount)}
							</TableCell>
							<TableCell>
								{date && moment(date).format("MM/DD/YYYY")}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell>Grand Total, $: </TableCell>
						<TableCell></TableCell>
						<TableCell align="right">
							{toFixed(grandTotal)}
						</TableCell>

						<TableCell></TableCell>
					</TableRow>
				</TableFooter>
			</Table>
		</Box>
	);
};
export default ReportHeader;
