import React, { useCallback, useRef } from "react";
import { CSVLink } from "react-csv";
import { Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import {
	Transactions,
	TransactionFilter,
	Spinner,
	HasPermissions,
} from "components";
import { useCurrentUser, useTransaction } from "queries";
import { permissionList, USER_EMPTY_OPTION } from "helpers";

const TransactionList = () => {
	// const queryClient = useQueryClient();
	const csvLink = useRef();
	const { useTransactionsListQuery, useDownloadCsvQuery } = useTransaction();
	const { data, isFetching, params, setParams } = useTransactionsListQuery();
	const {
		data: csv,
		isFetching: isFetchingCsv,
		refetch: downloadCsv,
	} = useDownloadCsvQuery();

	const { useUsersSelectQuery } = useCurrentUser();
	const { data: users = [] } = useUsersSelectQuery();

	const defaultValues = {
		user_id: "",
		from: null,
		status: "",
	};

	const handleFilterSubmit = useCallback(
		values => {
			// queryClient.cancelQueries("transactionsList");
			setParams(prevState => ({ ...prevState, page: 1, ...values }));
		},
		[setParams],
	);

	const handleSortChange = useCallback(
		sort =>
			setParams(prevState => ({
				...prevState,
				page: 1,
				sort_by: sort?.id,
				sort_desc: sort?.desc,
			})),
		[setParams],
	);

	const handlePageChange = useCallback(
		(_event, page) =>
			setParams(prevState => ({
				...prevState,
				page,
			})),
		[setParams],
	);

	const handleDownloadCsv = async () => {
		await downloadCsv();
		csvLink.current?.link?.click();
	};

	const usersOptions = [USER_EMPTY_OPTION, ...users] || [];

	return (
		<>
			<Spinner open={isFetchingCsv} />
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<h2>Transactions List</h2>
				<div>
					<HasPermissions
						requiredPermissions={[
							permissionList.canRunCurrentTransactions,
						]}
					>
						<Button
							variant="outlined"
							size="medium"
							component={Link}
							to="/transactions/new-transaction"
							sx={{ mr: 2 }}
						>
							Add a new transaction
						</Button>
					</HasPermissions>
					{data?.total_count > 0 && (
						<>
							<Button
								variant="contained"
								size="medium"
								onClick={handleDownloadCsv}
							>
								Download CSV
							</Button>
							<Box sx={{ display: "none" }}>
								<CSVLink
									data={csv || []}
									filename="transactions.csv"
									ref={csvLink}
									target="_blank"
								/>
							</Box>
						</>
					)}
				</div>
			</Box>

			<TransactionFilter
				onSubmit={handleFilterSubmit}
				defaultValues={defaultValues}
				users={usersOptions}
			/>

			<Transactions
				isLoading={isFetching}
				page={params?.page}
				count={data?.total_count}
				handlePageChange={handlePageChange}
				transactions={data?.items}
				handleSortChange={handleSortChange}
			/>
		</>
	);
};
export default TransactionList;
