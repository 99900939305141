import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { currentTerminalService } from "services";

export const useCurrentTerminal = () => {
	const [page, setPage] = useState(1);

	const useTerminalsListQuery = () => {
		const fetch = () =>
			currentTerminalService.getTerminals({
				page,
			});

		const queryResult = useQuery(["currentTerminalsList", page], fetch, {
			keepPreviousData: true,
			staleTime: 5000,
			retry: false,
		});

		return { ...queryResult, page, setPage };
	};

	const useTerminalsSelectQuery = location_id => {
		const fetch = () =>
			currentTerminalService.getTerminals({
				page: 1,
				page_size: 500,
				location_id,
			});

		const queryResult = useQuery(
			["currentTerminalsDropdown", location_id],
			fetch,
			{
				retry: false,
				select: ({ items }) =>
					items.map(({ id, note }) => ({
						value: id,
						name: note,
					})),
			},
		);

		return { ...queryResult };
	};

	const useTerminalQuery = () =>
		useMutation(id => currentTerminalService.getTerminal({ id }));

	return {
		useTerminalsListQuery,
		useTerminalsSelectQuery,
		useTerminalQuery,
	};
};
