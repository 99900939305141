import { failedTransactions, transactionStatuses } from "helpers";
import moment from "moment";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { transactionService } from "services";

export const useTransaction = status => {
	const [params, setParams] = useState({
		page: 1,
		status,
		sort_by: "id",
		sort_desc: true,
	});
	const [paramsSummaryReport, setParamsSummaryReport] = useState(null);
	const [paramsDailySummaryReport, setParamsDailySummaryReport] =
		useState(null);

	const [transactionStatus, setTransactionStatus] = useState(null);

	const useTransactionsListQuery = () => {
		const fetch = () => transactionService.getTransactions(params);

		const queryResult = useQuery(["transactionsList", params], fetch, {
			keepPreviousData: true,
			staleTime: 5000,
			retry: false,
		});

		return { ...queryResult, params, setParams };
	};

	const useDownloadCsvQuery = () => {
		const fetch = () => transactionService.getTransactionsCsv(params);

		const queryResult = useQuery(["transactionsCsv", params], fetch, {
			enabled: false,
			retry: false,
		});

		return { ...queryResult, params, setParams };
	};

	const useTransactionQuery = id => {
		const fetch = () => transactionService.getTransaction(id);

		const queryResult = useQuery(["transactionDetails", id], fetch, {
			keepPreviousData: true,
			staleTime: 5000,
			retry: false,
			onSuccess: ({ status }) => setTransactionStatus(status),
		});

		return { ...queryResult };
	};

	const useRelatedTransactionsQuery = id => {
		const fetch = () => transactionService.getRelatedTransactions(id);

		const queryResult = useQuery(["relatedTransactions", id], fetch, {
			keepPreviousData: true,
			staleTime: 5000,
			retry: false,
			enabled: transactionStatus === transactionStatuses.APPROVED,
		});

		return { ...queryResult };
	};

	const useAddTransactionQuery = (alert, history) => {
		return useMutation(
			values => transactionService.addTransaction(values),
			{
				onSuccess({ id }) {
					alert.success("Transaction has been added successfully.");
					history.push(`/transactions/${id}`);
				},
				onError(error) {
					alert.error(error);
				},
			},
		);
	};

	const useVoidTransactionQuery = (alert, onSuccess) => {
		return useMutation(
			({ id, is_signature_required }) => {
				return transactionService.voidTransaction(
					id,
					is_signature_required,
				);
			},
			{
				onSuccess() {
					onSuccess?.();
					alert.success("Transaction has been voided successfully.");
				},
				onError(error) {
					alert.error(error);
				},
			},
		);
	};

	const useReturnTransactionQuery = (alert, onSuccess) => {
		return useMutation(
			({ id, return_amount }) =>
				transactionService.returnTransaction(id, return_amount),
			{
				onSuccess() {
					onSuccess?.();
					alert.success(
						"Transaction has been returned successfully.",
					);
				},
				onError(error) {
					alert.error(error);
				},
			},
		);
	};

	const useRefreshTransactionQuery = alert => {
		return useMutation(id => transactionService.refreshTransaction(id), {
			onSuccess() {
				alert.success("Transaction has been refreshed successfully.");
			},
			onError(error) {
				alert.error(error);
			},
		});
	};

	const useSummaryReportQuery = () => {
		const fetch = () =>
			transactionService.getSummaryReport(paramsSummaryReport);

		const queryResult = useQuery(
			["summaryReport", paramsSummaryReport],
			fetch,
			{
				enabled: !!paramsSummaryReport,
				retry: false,
			},
		);

		return { ...queryResult, paramsSummaryReport, setParamsSummaryReport };
	};

	const useDailySummaryReportQuery = () => {
		const fetch = () =>
			transactionService.getTransactions(paramsDailySummaryReport);

		const queryResult = useQuery(
			["dailySummaryReport", paramsDailySummaryReport],
			fetch,
			{
				enabled: !!paramsDailySummaryReport,
				retry: false,
			},
		);

		return {
			...queryResult,
			paramsDailySummaryReport,
			setParamsDailySummaryReport,
		};
	};

	const useLastApprovedTransactionsQuery = () => {
		const fetch = () =>
			transactionService.getTransactions({
				status: transactionStatuses.APPROVED,
				page_size: 10,
				page: 1,
				sort_by: "created_at",
				sort_desc: true,
			});

		const queryResult = useQuery("lastApprovedTransactions", fetch, {
			retry: false,
		});

		return { ...queryResult };
	};

	const useTodayApprovedTransactionsQuery = () => {
		const fetch = () =>
			transactionService.getTransactions({
				status: transactionStatuses.APPROVED,
				page_size: 1,
				page: 1,
				from: moment(),
			});

		const queryResult = useQuery("todayApprovedTransactions", fetch, {
			retry: false,
		});

		return { ...queryResult };
	};

	const useTodayFailedTransactionsQuery = () => {
		const fetch = () =>
			transactionService.getTransactions({
				status: failedTransactions,
				page_size: 1,
				page: 1,
				from: moment(),
			});

		const queryResult = useQuery("todayFailedTransactions", fetch, {
			retry: false,
		});

		return { ...queryResult };
	};

	const useDuplicateTransactionQuery = () => {
		return useMutation(
			({ reference_number, actual_amount }) =>
				transactionService.getTransactions({
					status: transactionStatuses.APPROVED,
					page_size: 1,
					page: 1,
					from: moment(),
					reference_number,
					total_amount: actual_amount,
				}),
			{
				onSuccess() {},
			},
		);
	};

	return {
		useTransactionsListQuery,
		useTransactionQuery,
		useAddTransactionQuery,
		useRefreshTransactionQuery,
		useDownloadCsvQuery,
		useVoidTransactionQuery,
		useReturnTransactionQuery,
		useRelatedTransactionsQuery,
		useSummaryReportQuery,
		useDailySummaryReportQuery,
		useLastApprovedTransactionsQuery,
		useTodayApprovedTransactionsQuery,
		useTodayFailedTransactionsQuery,
		useDuplicateTransactionQuery,
	};
};
