import React from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container, Box, Button, Typography } from "@mui/material";

import { ReactHookFormInput } from "components";
import { required, minLength, useLocalStorage, roles } from "helpers";
import { authenticationService, currentUserService } from "services";
import { useAlert } from "react-alert";
import { useCurrentTerminal } from "queries";

const LoginPage = () => {
	const history = useHistory();
	const alert = useAlert();
	const { handleSubmit, control } = useForm();
	const [, setUserData] = useLocalStorage("userData", "");
	const [, setTerminal] = useLocalStorage("terminal", "");

	const { useTerminalQuery } = useCurrentTerminal();
	const { mutateAsync: getTerminal } = useTerminalQuery();

	const onSubmit = async ({ username, password }) => {
		await authenticationService.login({ username, password, alert });
		const user = await currentUserService.getCurrentUser();
		const userData = {
			...user,
			companyIName: user.company?.name,
		};
		setUserData(userData);

		// If user has only one assigned terminal
		if (user?.terminal_id) {
			const terminalInfo = await getTerminal(user?.terminal_id);
			setTerminal({
				location_id: "",
				terminal_id: terminalInfo?.id,
				terminal_name: terminalInfo?.name,
			});
			history.push("transactions/new-transaction");
		} else {
			history.push(roles[user.role].mainPage);
		}
	};

	return (
		<Container component="main" maxWidth="xs">
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Typography component="h1" variant="h5">
					Please login
				</Typography>

				<Box
					component="form"
					onSubmit={handleSubmit(onSubmit)}
					sx={{ mt: 1, width: "100%" }}
				>
					<ReactHookFormInput
						name="username"
						label="Username"
						control={control}
						autoFocus
						size="large"
						defaultValue=""
						rules={{
							validate: {
								required,
								minLength: minLength(3),
							},
						}}
					/>
					<ReactHookFormInput
						name="password"
						label="Password"
						type="password"
						control={control}
						size="large"
						autoComplete="current-password"
						defaultValue=""
						rules={{
							validate: {
								required,
								minLength: minLength(5),
							},
						}}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						size="large"
						sx={{ mt: 3, mb: 2 }}
					>
						Login
					</Button>
				</Box>
			</Box>
		</Container>
	);
};

export default LoginPage;
