import React from "react";
import { CompanyForm } from "components";
import { useAlert } from "react-alert";
import { useCompany, useUser } from "queries";
import { rolesTypes } from "helpers";

const AddCompany = () => {
	const alert = useAlert();
	const { useAddCompanyQuery, useAddCompanyImageQuery } = useCompany();
	const { useAddUserQuery } = useUser();
	const { mutateAsync: handleAddCompany } = useAddCompanyQuery(alert);
	const { mutateAsync: handleAddCompanyImage } =
		useAddCompanyImageQuery(alert);
	const { mutateAsync: handleAddUser } = useAddUserQuery(alert);

	const handleOnSubmit = async (values, selectedFile) => {
		const { id } = await handleAddCompany(values);
		selectedFile && handleAddCompanyImage({ id, file: selectedFile });
		handleAddUser({
			...values,
			company_id: id,
			role: rolesTypes.master_administrator,
		});
	};

	return (
		<>
			<h2> Create a new company</h2>
			<CompanyForm onSubmit={handleOnSubmit} />
		</>
	);
};
export default AddCompany;
