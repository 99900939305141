import React from "react";
import moment from "moment";
import { Box, Card, Typography } from "@mui/material";
import UsersList from "./usersList";

const ReportByUser = ({ data = {}, companyIName }) => {
	return (
		<Card variant="outlined" sx={{ mb: 5 }}>
			<Box className="report-header">
				<Typography component="div" variant="subtitle2">
					Merchant ID: {data?.location_id}
				</Typography>
				<Typography component="div" variant="subtitle2">
					{data?.location_name}
				</Typography>
				<Typography component="div" variant="subtitle2">
					Report run:&nbsp;
					{moment().format("MM/DD/YYYY HH:mm")}
				</Typography>
			</Box>

			<Typography variant="subtitle1" component="h5" p={2}>
				Main Batch for {`${companyIName} ${data?.location_name}`}:
				Summary report sorted by User ID
			</Typography>

			<UsersList data={data} />
		</Card>
	);
};
export default ReportByUser;
