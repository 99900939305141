import React from "react";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { formDataToObject, getDate, toFixed, toUpperCase } from "helpers";

export const TransactionReceiptPdf = ({ data }) => {
	const extData =
		(data?.response && formDataToObject(data?.response?.ext_data)) || {};
	const emvData =
		(data?.response && formDataToObject(data?.response?.emv_data)) || {};

	const styles = StyleSheet.create({
		page: {},
		wrapper: {
			width: "80mm",
			margin: 15,
			fontSize: 10,
			lineHeight: 2,
		},
		row: {
			display: "flex",
			justifyContent: "space-between",
			flexDirection: "row",
		},
		title: {
			fontFamily: "Helvetica-Bold",
			borderBottom: "1px solid #000",
			marginBottom: 15,
		},
		margin: {
			marginBottom: 20,
		},
		type: {
			fontFamily: "Helvetica-Bold",
			textAlign: "center",
			marginBottom: 10,
		},
		address: {
			textAlign: "center",
		},
	});

	return (
		<Document>
			<Page size="A4" style={styles.wrapper}>
				<View style={styles.wrapper}>
					<View>
						<Text style={styles.title}>Merchant Copy</Text>
					</View>
					<View>
						<Text style={styles.address}>
							{data?.terminal?.name}
						</Text>
						<Text style={styles.address}>
							{data?.location?.address_line1}
						</Text>
						<Text style={styles.address}>
							{`${data?.location?.city} ${data?.location?.region_code} ${data?.location?.postal_code}`}
						</Text>
						<Text style={styles.address}>
							{data?.location?.phone}
						</Text>
					</View>
					<View style={{ marginBottom: 10 }}>
						<View style={styles.row}>
							<View style={styles.column}>
								<Text>
									{data?.created_at &&
										getDate(data?.created_at)}
								</Text>
							</View>
							<View style={styles.column}>
								<Text>
									{data?.created_at &&
										getDate(data?.created_at, "HH:mm")}
								</Text>
							</View>
						</View>
					</View>

					<View style={styles.type}>
						<Text>{toUpperCase(data?.type)}</Text>
					</View>

					<View style={styles.margin}>
						<View style={styles.row}>
							<View style={styles.column}>
								<Text>Trans #: {data?.reference_id}</Text>
							</View>
							<View style={styles.column}>
								<Text>Batch #: {extData?.BatchNum}</Text>
							</View>
						</View>
					</View>

					<View style={styles.margin}>
						<View style={styles.row}>
							<View style={styles.column}>
								<Text style={{ fontFamily: "Helvetica-Bold" }}>
									{data?.credit_card?.card_network}
								</Text>
							</View>
							<View style={styles.column}>
								<Text style={{ fontFamily: "Helvetica-Bold" }}>
									CHIP
								</Text>
							</View>
						</View>

						<View style={styles.row}>
							<View style={styles.column}>
								<Text>
									*************
									{data?.credit_card?.last_four_digits}
								</Text>
							</View>
							<View style={styles.column}>
								<Text>**/**</Text>
							</View>
						</View>
					</View>

					<View style={styles.margin}>
						<View style={styles.row}>
							<View style={styles.column}>
								<Text>Cardholder:</Text>
							</View>
							<View style={styles.column}>
								<Text>
									{decodeURIComponent(
										data?.credit_card?.card_holder,
									)}
								</Text>
							</View>
						</View>

						<View style={styles.row}>
							<View style={styles.column}>
								<Text>AMOUNT:</Text>
							</View>
							<View style={styles.column}>
								<Text>${toFixed(data?.total_amount)}</Text>
							</View>
						</View>

						<View style={styles.row}>
							<View style={styles.column}>
								<Text>Our Reference:</Text>
							</View>
							<View style={styles.column}>
								<Text>{data?.reference_number}</Text>
							</View>
						</View>
					</View>

					<View style={styles.margin}>
						<View style={styles.row}>
							<View style={styles.column}>
								<Text>Resp:</Text>
							</View>
							<View style={styles.column}>
								<Text>
									{decodeURIComponent(
										data?.response?.message,
									)}
								</Text>
							</View>
						</View>

						<View style={styles.row}>
							<View style={styles.column}>
								<Text>Code:</Text>
							</View>
							<View style={styles.column}>
								<Text>{data?.response?.auth_code}</Text>
							</View>
						</View>

						<View style={styles.row}>
							<View style={styles.column}>
								<Text>Ref #:</Text>
							</View>
							<View style={styles.column}>
								<Text>{data?.response?.pnref}</Text>
							</View>
						</View>
					</View>

					<View style={styles.margin}>
						<View style={styles.row}>
							<View style={styles.column}>
								<Text>App Name:</Text>
							</View>
							<View style={styles.column}>
								<Text>{emvData?.AppName}</Text>
							</View>
						</View>

						<View style={styles.row}>
							<View style={styles.column}>
								<Text>AID:</Text>
							</View>
							<View style={styles.column}>
								<Text>{emvData?.AID}</Text>
							</View>
						</View>

						<View style={styles.row}>
							<View style={styles.column}>
								<Text>TVR:</Text>
							</View>
							<View style={styles.column}>
								<Text>{emvData?.TVR}</Text>
							</View>
						</View>

						<View style={styles.row}>
							<View style={styles.column}>
								<Text>TSI:</Text>
							</View>
							<View style={styles.column}>
								<Text>{emvData?.TSI}</Text>
							</View>
						</View>
					</View>

					<View style={styles.row}>
						<Text style={{ fontSize: "8pt", textAlign: "center" }}>
							Cardholder acknowledges receipt of goods and
							obligations set forth by the card holder's agreement
							with issuer.
						</Text>
					</View>
				</View>
			</Page>
		</Document>
	);
};
