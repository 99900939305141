import React from "react";
import { TotalCard, TransactionReportList } from "components";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { lightGreen, red } from "@mui/material/colors";
import ExportList from "containers/exportList";
import { useTransaction } from "queries";

const Dashboard = () => {
	const {
		useLastApprovedTransactionsQuery,
		useTodayApprovedTransactionsQuery,
		useTodayFailedTransactionsQuery,
	} = useTransaction();
	const { data: transactions, isFetching: isTransactionsFetching } =
		useLastApprovedTransactionsQuery();

	const {
		data: approvedTransactions,
		isFetching: isApprovedTransactionsFetching,
	} = useTodayApprovedTransactionsQuery();

	const {
		data: failedTransactions,
		isFetching: isFailedTransactionsFetching,
	} = useTodayFailedTransactionsQuery();

	return (
		<>
			<h2>Dashboard</h2>
			<Grid container spacing={3} mb={3}>
				<Grid item xs={12} md={4}>
					<TotalCard
						theme={lightGreen[700]}
						to="/reports/approved-transactions"
						isFetching={isApprovedTransactionsFetching}
						count={approvedTransactions?.total_count}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<TotalCard
						theme={red[700]}
						title="Today's Failed Transactions"
						to="/reports/failed-transactions"
						isFetching={isFailedTransactionsFetching}
						count={failedTransactions?.total_count}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} mb={3}>
				<Grid item xs={12}>
					<Card sx={{ border: "1px solid #ccc" }}>
						<CardHeader
							title="Search and Download Approved or Failed Transactions"
							sx={{ borderBottom: "1px solid #ccc" }}
						/>

						<ExportList withoutTitle />
					</Card>
				</Grid>
			</Grid>

			<Grid container spacing={3} mb={3}>
				<Grid item xs={12}>
					<Card sx={{ border: "1px solid #ccc" }}>
						<CardHeader
							title="Recent 10 Approved Transaction"
							sx={{ borderBottom: "1px solid #ccc" }}
						/>
						<CardContent>
							<TransactionReportList
								isLoading={isTransactionsFetching}
								transactions={transactions?.items}
							/>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};
export default Dashboard;
