import React from "react";
import { useForm } from "react-hook-form";
import { Container, Box, Button, Grid, Typography } from "@mui/material";
import { ReactHookFormSelect } from "components";
import { SWITCHER_VALUES, toFixed, getSwitcherValue } from "helpers";

export const TransactionVoidForm = ({ onSubmit, amount, cancel }) => {
	const { handleSubmit, control } = useForm({
		mode: "onChange",
		defaultValues: { is_signature_required: "no" },
	});

	const handleOnSubmit = values => {
		const value = getSwitcherValue(values.is_signature_required);
		return onSubmit(value);
	};

	return (
		<Container component="main" className="formWrapper">
			<Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
				<Typography component="h6" variant="h6" mb={2}>
					Void Transaction
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography component="p" variant="body1">
							Do you want to you void the amount&nbsp;
							<strong>${toFixed(amount)}</strong>?
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{ maxWidth: 350 }}>
						<ReactHookFormSelect
							name="is_signature_required"
							label="Signature Capture"
							defaultValue=""
							control={control}
							size="small"
							variant="outlined"
							options={SWITCHER_VALUES}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							sx={{ mr: 3 }}
							type="submit"
							variant="contained"
							size="large"
						>
							Submit
						</Button>
						<Button
							variant="outlined"
							size="large"
							onClick={cancel}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
};
