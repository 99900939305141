import React from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Box, Button, Grid, CardContent, Card } from "@mui/material";
import {
	ReactHookFormSelect,
	ReactHookFormDatePicker,
	DataContainer,
	HasPermissions,
	ReactHookFormCurrencyInput,
} from "components";
import {
	permissionList,
	transactionStatuses,
	TRANSACTION_SEARCH_STATUS,
} from "helpers";

export const ExportListFilter = ({
	onSubmit,
	isFetching,
	users,
	terminals,
	locations,
}) => {
	const defaultValues = {
		from: moment(),
		to: moment(),
		status: transactionStatuses.APPROVED,
		total_amount: "",
		user_id: "",
		terminal_id: "",
		transaction_id: "",
		reference_number: "",
		location_id: "",
	};
	const { handleSubmit, control, reset } = useForm({
		mode: "onChange",
		defaultValues,
	});

	const handleClearFilter = () => reset();

	return (
		<DataContainer isFetching={isFetching}>
			<Card
				sx={{
					display: "block",
					displayPrint: "none",
					mb: 2,
					boxShadow: "none",
				}}
			>
				<CardContent>
					<Box
						component="form"
						noValidate
						onSubmit={handleSubmit(onSubmit)}
					>
						<Grid container rowSpacing={1} columnSpacing={2}>
							<Grid item xs={12} md={4} lg={3}>
								<ReactHookFormSelect
									name="status"
									label="Transaction Status"
									defaultValue=""
									control={control}
									size="small"
									variant="outlined"
									options={TRANSACTION_SEARCH_STATUS}
								/>
							</Grid>
						</Grid>
						<Grid container rowSpacing={1} columnSpacing={2}>
							<HasPermissions
								requiredPermissions={[
									permissionList.canViewCurrentUsers,
								]}
							>
								<Grid item xs={12} md={4} lg={3}>
									<ReactHookFormSelect
										name="user_id"
										label="Select User"
										defaultValue=""
										control={control}
										size="small"
										variant="outlined"
										options={users}
									/>
								</Grid>
							</HasPermissions>
							<Grid item xs={12} md={4} lg={3}>
								<ReactHookFormSelect
									name="terminal_id"
									label="Select Terminal"
									defaultValue=""
									control={control}
									size="small"
									variant="outlined"
									options={terminals}
								/>
							</Grid>
							<Grid item xs={12} md={4} lg={3}>
								<ReactHookFormSelect
									name="location_id"
									label="Select Location"
									defaultValue=""
									control={control}
									size="small"
									variant="outlined"
									options={locations}
								/>
							</Grid>
						</Grid>

						<Grid container rowSpacing={1} columnSpacing={2}>
							<Grid item xs={12} md={4} lg={3}>
								<ReactHookFormDatePicker
									control={control}
									label="From Date"
									name="from"
									size="small"
									defaultValue=""
								/>
							</Grid>

							<Grid item xs={12} md={4} lg={3}>
								<ReactHookFormDatePicker
									control={control}
									label="To Date"
									name="to"
									size="small"
									defaultValue=""
								/>
							</Grid>

							<Grid item xs={12} md={4} lg={3}>
								<ReactHookFormCurrencyInput
									name="total_amount"
									label="Amount"
									control={control}
									size="small"
								/>
							</Grid>
						</Grid>
						<Grid container rowSpacing={1} columnSpacing={2}>
							<Grid item xs={12}>
								<Button
									type="submit"
									variant="contained"
									size="large"
									sx={{ mr: 2, mt: "7px", height: "40px" }}
								>
									Generate CSV File
								</Button>
								<Button
									onClick={handleSubmit(handleClearFilter)}
									variant="outlined"
									size="large"
									sx={{ mt: "7px", height: "40px" }}
								>
									Clear
								</Button>
							</Grid>
						</Grid>
					</Box>
				</CardContent>
			</Card>
		</DataContainer>
	);
};
