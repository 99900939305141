import React from "react";
import { Container, Typography } from "@mui/material";

const PageNotFound = () => {
	return (
		<Container
			disableGutters
			maxWidth="sm"
			component="main"
			sx={{ pt: 8, pb: 6 }}
		>
			<Typography
				component="h1"
				variant="h1"
				align="center"
				color="#88b03b"
				gutterBottom
			>
				404
			</Typography>
			<Typography
				variant="h5"
				align="center"
				color="text.secondary"
				component="p"
			>
				Oops! Page not be found or <br /> you don't have permission for
				this page
			</Typography>
		</Container>
	);
};
export default PageNotFound;
