export const baseEmailTemplateRegex =
	/^[\w\d-.]{1,50}@[a-z\d-.]{1,251}\.[a-z\d-]{1,50}$/i;
export const beforeDomainRegex = /^([a-z\d](((\.(?!\.))|[-\w])*[a-z\d])*)@$/i;
export const subDomainRegex = /^[a-z\d]+([a-z\d]*-(?!$)[a-z\d]*)*$/i;

export const maxLength = max => value => {
	return value && value.length > max
		? `Max length is  ${max} characters`
		: undefined;
};

export const minLength = min => value => {
	return value && value.length < min
		? `Min length is ${min} characters`
		: undefined;
};

export const required = value =>
	requiredWithMessage("Field is required")(value);

export const requiredWithMessage =
	(message = "Field is required") =>
	value => {
		const valueIsEmpty = !value;
		const valueIsNotZero = value !== 0;
		const isArrayEmpty = Array.isArray(value) && !value.length;

		if ((valueIsEmpty && valueIsNotZero) || isArrayEmpty) {
			return message;
		}
		return undefined;
	};

export const onlyDigits = value => {
	const error = "Must be numbers only";

	if (!value) return undefined;

	let regNumbers = /^\d+$/;
	return regNumbers.test(value) ? undefined : error;
};

export const onlyFloats = value => {
	const error = "Format should be 0.00";

	if (!value) return undefined;

	let regNumbers = /^\d*(\.\d{0,2})?$/;
	return regNumbers.test(value) ? undefined : error;
};

export const emailValid = value => {
	const error = "Invalid email address";

	if (!value) return undefined;
	if (value?.length > 255) return error;
	if (!baseEmailTemplateRegex.test(value)) return error;

	const splitted = value.split("@");
	if (splitted.length !== 2) return error;

	if (!beforeDomainRegex.test(splitted[0] + "@")) return error;

	const splittedByDot = splitted[1].split(".");
	if (splittedByDot.length < 2) return error;

	const anySubDomainIsInvalid = splittedByDot.some(
		x => !x || x.length > 50 || !subDomainRegex.test(x),
	);

	if (anySubDomainIsInvalid) return error;

	let regEmail =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
	return regEmail.test(value) ? undefined : error;
};

export const confirmByName = message => (value, matchValue) => {
	return value === matchValue ? null : message;
};

export const passwordConfirmation = confirmByName("Passwords don't match");
