import React, { useMemo } from "react";
import { Button, IconButton, Typography, Box } from "@mui/material";
import { green } from "@mui/material/colors";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { CustomTable } from "components";
import { useCompany } from "queries";
import { getDate, BASE_URL } from "helpers";

const CompanyList = () => {
	const { useCompaniesListQuery } = useCompany();
	const { data, isFetching, page, setPage } = useCompaniesListQuery();

	const handlePageChange = (_event, page) => setPage(page);

	const columns = useMemo(
		() => [
			{
				Header: "Company ID",
				accessor: "id",
			},
			{
				Header: "Company Name",
				accessor: "name",
			},
			{
				Header: "Image",
				accessor: "image",
				Cell: ({ row: { original } }) => (
					<img
						src={`${BASE_URL}/api/companies/${original?.id}/image`}
						alt=""
						width="50px"
					/>
				),
			},
			{
				Header: "Email",
				accessor: "email",
			},
			{
				Header: "Admin User",
				accessor: "key_contact_person_name",
			},
			{
				Header: "Status",
				accessor: "is_active",
				Cell: ({ value }) => {
					return value ? (
						<Typography fontWeight="500" color={green[500]}>
							Active
						</Typography>
					) : (
						<Typography fontWeight="500" color="error">
							Suspended
						</Typography>
					);
				},
			},
			{
				Header: "Created Date",
				accessor: "created_at",
				Cell: ({ value }) => getDate(value),
			},
			{
				Header: "Options",
				id: "options",
				Cell: ({ row: { original } }) => (
					<IconButton
						color="primary"
						title="Edit Company"
						component={Link}
						to={`/companies/${original?.id}`}
					>
						<EditIcon />
					</IconButton>
				),
			},
		],
		[],
	);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<h2>Company List</h2>
				<Button
					variant="outlined"
					size="medium"
					component={Link}
					to="/companies/add-company"
				>
					Add a new company
				</Button>
			</Box>

			<CustomTable
				columns={columns}
				data={data?.items}
				isLoading={isFetching}
				handlePageChange={handlePageChange}
				count={data?.total_count}
				pageIndex={page}
				hover
				disableSortBy={true}
			/>
		</>
	);
};

export default CompanyList;
