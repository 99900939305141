import { intersectionOfArrays } from "helpers";

export const permissionList = {
	canViewDashboard: "canViewDashboard",
	canRunCurrentTransactions: "canRunCurrentTransactions",
	canRunCurrentReports: "canRunCurrentReports",
	canSetupCurrentUsers: "canSetupCurrentUsers",
	canChooseTerminal: "canChooseTerminal",

	canViewCurrentUsers: "canViewCurrentUsers",
	canViewCurrentTerminals: "canViewCurrentTerminals",

	canSetupCompanies: "canSetupCompanies",
	canSetupLocations: "canSetupLocations",
	canSetupTerminals: "canSetupTerminals",
};

export const rolesTypes = {
	system_administrator: "system_administrator",
	master_administrator: "master_administrator",
	company_administrator: "company_administrator",
	manager: "manager",
	user: "user",
	accounting_user: "accounting_user",
};

export const roles = {
	[rolesTypes.system_administrator]: {
		name: "System Administrator",
		mainPage: "/companies",
		permissions: [
			permissionList.canSetupCompanies,
			permissionList.canSetupTerminals,
			permissionList.canSetupLocations,
		],
	},
	[rolesTypes.master_administrator]: {
		name: "Master Administrator",
		mainPage: "/select-terminal",
		permissions: [
			permissionList.canRunCurrentTransactions,
			permissionList.canRunCurrentReports,
			permissionList.canSetupCurrentUsers,
			permissionList.canChooseTerminal,
			permissionList.canViewDashboard,
			permissionList.canViewCurrentUsers,
			permissionList.canViewCurrentTerminals,
		],
	},
	[rolesTypes.company_administrator]: {
		name: "Security Administrator",
		mainPage: "/select-terminal",
		permissions: [
			permissionList.canRunCurrentTransactions,
			permissionList.canRunCurrentReports,
			permissionList.canSetupCurrentUsers,
			permissionList.canChooseTerminal,
			permissionList.canViewDashboard,
			permissionList.canViewCurrentUsers,
			permissionList.canViewCurrentTerminals,
		],
	},
	[rolesTypes.manager]: {
		name: "Administrator",
		mainPage: "/select-terminal",
		permissions: [
			permissionList.canRunCurrentTransactions,
			permissionList.canRunCurrentReports,
			permissionList.canChooseTerminal,
			permissionList.canViewDashboard,
			permissionList.canViewCurrentUsers,
			permissionList.canViewCurrentTerminals,
		],
	},
	[rolesTypes.user]: {
		name: "User",
		mainPage: "/select-terminal",
		permissions: [
			permissionList.canRunCurrentTransactions,
			permissionList.canRunCurrentReports,
			permissionList.canChooseTerminal,
			permissionList.canViewDashboard,
			permissionList.canViewCurrentTerminals,
		],
	},
	[rolesTypes.accounting_user]: {
		name: "Accounting User",
		mainPage: "/transactions",
		permissions: [
			permissionList.canRunCurrentReports,
			permissionList.canViewCurrentUsers,
			permissionList.canViewDashboard,
		],
	},
};

export const getRoleTitle = role => roles[role]?.name || "";

export const ROLES_VALUES = [
	"company_administrator",
	"manager",
	"user",
	"accounting_user",
].map(role => ({
	name: roles[role].name,
	value: role,
}));

export function hasPermissions(requiredPermissions) {
	if (!requiredPermissions) {
		return true;
	}
	if (!Array.isArray(requiredPermissions)) {
		requiredPermissions = [requiredPermissions];
	}

	const { role } = JSON.parse(localStorage.getItem("userData")) || null;
	const { permissions } = roles[role];

	return intersectionOfArrays(requiredPermissions, permissions).length > 0;
}
