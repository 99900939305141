import React from "react";
import { useForm } from "react-hook-form";
import { Container, Box, Button, Grid } from "@mui/material";

import {
	ReactHookFormDatePicker,
	DataContainer,
	HasPermissions,
	ReactHookFormSelect,
} from "components";
import { permissionList } from "helpers";

export const DailySummaryFilter = ({
	onSubmit,
	users,
	defaultValues,
	isFetching,
	withoutUser = false,
}) => {
	const { handleSubmit, control } = useForm({
		defaultValues,
	});

	return (
		<DataContainer isFetching={isFetching} height={40}>
			<Container
				className="filterWrapper"
				sx={{ display: "block", displayPrint: "none" }}
			>
				<Box component="form" onSubmit={handleSubmit(onSubmit)}>
					<Grid container rowSpacing={1} columnSpacing={2}>
						{!withoutUser && (
							<HasPermissions
								requiredPermissions={[
									permissionList.canViewCurrentUsers,
								]}
							>
								<Grid item xs={12} md={4}>
									<ReactHookFormSelect
										name="user_id"
										label="Select User"
										control={control}
										size="small"
										variant="outlined"
										options={users}
										margin="dense"
									/>
								</Grid>
							</HasPermissions>
						)}
						<Grid item xs={12} md={4}>
							<ReactHookFormDatePicker
								control={control}
								label="Transaction Date"
								name="from"
								size="small"
								margin="dense"
							/>
						</Grid>

						<Grid item xs={12} md={4}>
							<Button
								type="submit"
								variant="contained"
								size="large"
								sx={{ mr: 2, mt: "7px", height: "40px" }}
							>
								Submit
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</DataContainer>
	);
};
