import React, { useCallback, useEffect } from "react";

import { Box } from "@mui/material";
import moment from "moment";
import { DailySummaryFilter, TransactionReportList } from "components";
import { useCurrentUser, useTransaction } from "queries";
import { TotalBlock } from "./totalBlock";
import { useLocalStorage } from "helpers";

const DailySummaryReport = () => {
	const { useUsersSelectQuery } = useCurrentUser();
	const { data: users, isFetching: isUsersFetching } = useUsersSelectQuery();

	const { useDailySummaryReportQuery } = useTransaction();
	const {
		data,
		isFetching,
		setParamsDailySummaryReport,
		paramsDailySummaryReport,
	} = useDailySummaryReportQuery();

	const { items, total_count, ...total } = data || {};
	const [userData] = useLocalStorage("userData", "");

	const handleFilterSubmit = useCallback(
		async values => {
			await setParamsDailySummaryReport(prevState => {
				return {
					...prevState,
					page: 1,
					...values,
				};
			});
		},
		[setParamsDailySummaryReport],
	);

	const defaultValues = {
		user_id: userData?.id,
		from: moment(),
	};

	useEffect(() => {
		setParamsDailySummaryReport({
			page: 1,
			user_id: userData?.id,
			page_size: 500,
			from: moment(),
		});
	}, [userData?.id, setParamsDailySummaryReport]);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					displayPrint: "none",
				}}
			>
				<h2>Daily User Summary Report</h2>
			</Box>

			<DailySummaryFilter
				onSubmit={handleFilterSubmit}
				defaultValues={defaultValues}
				users={users}
				isFetching={isUsersFetching}
			/>

			<TransactionReportList
				isLoading={isFetching}
				page={paramsDailySummaryReport?.page}
				count={total_count}
				transactions={items}
			/>

			<TotalBlock data={total} />
		</>
	);
};
export default DailySummaryReport;
