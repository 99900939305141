import { QueryClient } from "react-query";

export const queryClient = new QueryClient();

queryClient.setDefaultOptions({
	queries: {
		refetchOnWindowFocus: false,
		retry: false,
	},
});
