import React, { useState } from "react";
import { Menu, MenuItem, Button } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useHistory } from "react-router";
import { logout } from "helpers";

const HeaderMenu = ({ userName = "" }) => {
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);

	const handleMenu = event => setAnchorEl(event.currentTarget);
	const handleLogout = () => logout(history);
	const handleClose = () => setAnchorEl(null);

	return (
		<>
			<Button
				id="basic-button"
				aria-controls="basic-menu"
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleMenu}
				color="inherit"
				startIcon={<AccountCircle />}
			>
				{userName}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				<MenuItem onClick={handleClose}>My Account</MenuItem>
				<MenuItem onClick={handleLogout}>Logout</MenuItem>
			</Menu>
		</>
	);
};
export default HeaderMenu;
