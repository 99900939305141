import React, { useCallback, useState } from "react";
import moment from "moment";
import { Box } from "@mui/material";
import { DailySummaryFilter, DataContainer } from "components";
import { useTransaction } from "queries";
import ReportByUser from "./reportByUser";
import ReportHeader from "./reportHeader";
import { useLocalStorage } from "helpers";

const GlobalSummaryReport = () => {
	const [date, setDate] = useState("");
	const [userData] = useLocalStorage("userData", "");

	const { useSummaryReportQuery } = useTransaction();
	const { data, isFetching, setParamsSummaryReport } =
		useSummaryReportQuery();

	const defaultValues = {
		from: moment(),
	};

	const handleFilterSubmit = useCallback(
		values => {
			setParamsSummaryReport(values);
			setDate(values?.from);
		},
		[setParamsSummaryReport],
	);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					displayPrint: "none",
				}}
			>
				<h2>Global Summary Report </h2>
			</Box>
			<DailySummaryFilter
				onSubmit={handleFilterSubmit}
				defaultValues={defaultValues}
				isFetching={isFetching}
				withoutUser
			/>
			<DataContainer isFetching={isFetching} isData={data?.length > 0}>
				<Box sx={{ maxWidth: 900 }}>
					<ReportHeader
						data={data}
						date={date}
						companyIName={userData?.companyIName || ""}
					/>
					{data?.map(item => (
						<ReportByUser
							key={item?.location_id}
							data={item}
							companyIName={userData?.companyIName || ""}
						/>
					))}
				</Box>
			</DataContainer>
		</>
	);
};
export default GlobalSummaryReport;
