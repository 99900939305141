import React from "react";
import { Button, Box } from "@mui/material";
import { useLocalStorage } from "helpers";

export const Actions = ({
	handleVoidClick,
	handleReturnClick,
	handlePartialReturnClick,
	isReturn,
}) => {
	const [userData] = useLocalStorage("userData", {});

	return (
		<Box sx={{ display: "block", displayPrint: "none" }}>
			{!isReturn && (
				<>
					{userData?.is_allow_void && (
						<Button
							variant="contained"
							color="error"
							size="medium"
							sx={{ mr: 2 }}
							onClick={handleVoidClick}
						>
							Void
						</Button>
					)}
					{userData?.is_allow_return && (
						<Button
							variant="contained"
							color="warning"
							size="medium"
							sx={{ mr: 2 }}
							onClick={handleReturnClick}
						>
							Return
						</Button>
					)}
				</>
			)}
			{userData?.is_allow_return && (
				<Button
					variant="contained"
					color="info"
					size="medium"
					onClick={handlePartialReturnClick}
				>
					Partial Return
				</Button>
			)}
		</Box>
	);
};
