import { getSwitcherValue } from "helpers";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { companyService } from "services";

export const useCompany = () => {
	const [page, setPage] = useState(1);

	const useCompaniesListQuery = () => {
		const fetch = () =>
			companyService.getCompanies({
				page,
			});

		const queryResult = useQuery(["companiesList", page], fetch, {
			keepPreviousData: true,
			staleTime: 5000,
			retry: false,
		});

		return { ...queryResult, page, setPage };
	};

	const useGetCompanyQuery = id => {
		const fetch = () => companyService.getCompanyData(id);

		const queryResult = useQuery(["company", id], fetch, {
			enabled: !!id,
			retry: false,
			select: ({
				is_allow_blind_refunds,
				is_default_terminal_enable,
				...data
			}) => ({
				...data,
				is_allow_blind_refunds: is_allow_blind_refunds ? "yes" : "no",
				is_default_terminal_enable: is_default_terminal_enable
					? "yes"
					: "no",
			}),
		});

		return { ...queryResult };
	};

	const useCompaniesSelectQuery = () => {
		const fetch = () =>
			companyService.getCompanies({
				page: 1,
				page_size: 500,
			});

		const queryResult = useQuery(["companiesDropdown"], fetch, {
			retry: false,
			select: ({ items }) =>
				items.map(({ id, name }) => ({
					value: id,
					name: name,
				})),
		});

		return { ...queryResult };
	};

	const useAddCompanyQuery = alert => {
		return useMutation(
			({
				is_default_terminal_enable,
				is_allow_blind_refunds,
				...values
			}) => {
				const company = {
					...values,
					is_default_terminal_enable: getSwitcherValue(
						is_default_terminal_enable,
					),
					is_allow_blind_refunds: getSwitcherValue(
						is_allow_blind_refunds,
					),
				};
				return companyService.addCompany(company);
			},
			{
				onSuccess() {
					alert.success("Company has been added successfully.");
				},
				onError(error) {
					alert.error(error);
				},
			},
		);
	};

	const useUpdateCompanyQuery = (id, alert) => {
		return useMutation(
			({
				is_default_terminal_enable,
				is_allow_blind_refunds,
				...values
			}) => {
				const company = {
					...values,
					is_default_terminal_enable: getSwitcherValue(
						is_default_terminal_enable,
					),
					is_allow_blind_refunds: getSwitcherValue(
						is_allow_blind_refunds,
					),
				};
				return companyService.updateCompany(company, id);
			},
			{
				onSuccess() {
					alert.success("Company has been updated successfully.");
				},
				onError(error) {
					alert.error(error);
				},
			},
		);
	};

	const useAddCompanyImageQuery = alert => {
		return useMutation(
			({ id, file }) => companyService.addCompanyImage(id, file),
			{
				onError(error) {
					alert.error(error);
				},
			},
		);
	};

	return {
		useCompaniesListQuery,
		useCompaniesSelectQuery,
		useAddCompanyQuery,
		useUpdateCompanyQuery,
		useAddCompanyImageQuery,
		useGetCompanyQuery,
	};
};
