import React, { useCallback, useRef } from "react";
import { CSVLink } from "react-csv";
import { Button, Box } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

import { Transactions, TransactionFilter, Spinner } from "components";
import { useCurrentUser, useTransaction } from "queries";
import { transactionStatuses, USER_EMPTY_OPTION } from "helpers";

const ApprovedTransactions = () => {
	const csvLink = useRef();
	const { useTransactionsListQuery, useDownloadCsvQuery } = useTransaction(
		transactionStatuses.APPROVED,
	);
	const { data, isFetching, params, setParams } = useTransactionsListQuery();
	const {
		data: csv,
		isFetching: isFetchingCsv,
		refetch: downloadCsv,
	} = useDownloadCsvQuery();

	const { useUsersSelectQuery } = useCurrentUser();
	const { data: users = [] } = useUsersSelectQuery();

	const defaultValues = {
		user_id: "",
		from: null,
		status: transactionStatuses.APPROVED,
	};

	const handleFilterSubmit = useCallback(
		values => {
			setParams(prevState => ({
				...prevState,
				page: 1,
				...values,
			}));
		},
		[setParams],
	);

	const handleSortChange = useCallback(
		sort =>
			setParams(prevState => ({
				...prevState,
				page: 1,
				sort_by: sort?.id,
				sort_desc: sort?.desc,
			})),
		[setParams],
	);

	const handlePageChange = useCallback(
		(_event, page) =>
			setParams(prevState => ({
				...prevState,
				page,
			})),
		[setParams],
	);

	const handleDownloadCsv = async () => {
		await downloadCsv();
		csvLink.current?.link?.click();
	};

	const handlePrintTable = () => window.print();

	const usersOptions = [USER_EMPTY_OPTION, ...users] || [];

	return (
		<>
			<Spinner open={isFetchingCsv} />
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					displayPrint: "none",
				}}
			>
				<h2>Approved Transactions List</h2>
				<div>
					{data?.total_count > 0 && (
						<>
							<Button
								variant="outlined"
								size="medium"
								onClick={handlePrintTable}
								sx={{ mr: 2 }}
								startIcon={<PrintIcon />}
							>
								Print
							</Button>
							<Button
								variant="contained"
								size="medium"
								onClick={handleDownloadCsv}
							>
								Download CSV
							</Button>
							<Box sx={{ display: "none" }}>
								<CSVLink
									data={csv || []}
									filename="approved_transactions.csv"
									ref={csvLink}
									target="_blank"
								/>
							</Box>
						</>
					)}
				</div>
			</Box>

			<TransactionFilter
				onSubmit={handleFilterSubmit}
				defaultValues={defaultValues}
				users={usersOptions}
				withoutStatus
			/>

			<Transactions
				isLoading={isFetching}
				page={params?.page}
				count={data?.total_count}
				handlePageChange={handlePageChange}
				transactions={data?.items}
				handleSortChange={handleSortChange}
			/>
		</>
	);
};
export default ApprovedTransactions;
