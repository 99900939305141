import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container, Box, Button, Grid } from "@mui/material";
import {
	ReactHookFormInput,
	ReactHookFormSelect,
	DataContainer,
} from "components";
import { maxLength, minLength, onlyDigits, required } from "helpers";

import { useHistory } from "react-router";
import { useCompany, useLocation, useTerminal } from "queries";

export const TerminalForm = ({ onSubmit, id }) => {
	const history = useHistory();

	const { useGetTerminalQuery } = useTerminal();
	const { data: terminalData, isFetching: isTerminalFetching } =
		useGetTerminalQuery(id);

	const { handleSubmit, control, reset, watch, setValue } = useForm({
		defaultValues: {
			company_id: 1 || "",
			register_id: "",
			location_id: 1 || "",
			authentication_key: "",
			additional_information: "",
			name: "",
			address: "",
			city: "",
			region_code: "",
			postal_code: "",
			phone: "",
			note: "",
			color: "#ffffff",
		},
	});
	const company = watch("company_id");

	const { useCompaniesSelectQuery } = useCompany();
	const { data: companies = [], isFetching: isCompaniesFetching } =
		useCompaniesSelectQuery();
	const { useLocationsSelectQuery } = useLocation();
	const { data: locations = [], setCompanyId } = useLocationsSelectQuery();

	useEffect(() => {
		setCompanyId(company);
		setValue("location_id", "");
	}, [company, setCompanyId, setValue]);

	useEffect(() => reset(terminalData), [reset, terminalData]);

	const toListPage = () => history.push("/terminals");

	const handleOnSubmit = async values => {
		await onSubmit(values);
		toListPage();
	};

	const handleOnSubmitAndMore = async values => {
		await onSubmit(values);
		reset();
	};

	const isFetching = isTerminalFetching || isCompaniesFetching;

	return (
		<DataContainer isFetching={isFetching}>
			<Container component="main" className="formWrapper">
				<Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
					<Grid container rowSpacing={1} columnSpacing={5}>
						<Grid item xs={12} md={6}>
							<ReactHookFormSelect
								name="company_id"
								label="Select Company*"
								defaultValue=""
								control={control}
								size="small"
								variant="outlined"
								options={companies}
								rules={{ validate: { required } }}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<ReactHookFormSelect
								name="location_id"
								label="Select Company Location*"
								defaultValue=""
								control={control}
								size="small"
								variant="outlined"
								options={locations}
								rules={{ validate: { required } }}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<ReactHookFormInput
								name="register_id"
								label="Terminal Id*"
								control={control}
								size="small"
								type="number"
								defaultValue=""
								rules={{
									validate: {
										required,
										onlyDigits,
									},
								}}
							/>
						</Grid>

						<Grid item xs={12} md={4}>
							<ReactHookFormInput
								name="authentication_key"
								label="Authkey*"
								defaultValue=""
								control={control}
								size="small"
								rules={{ validate: { required } }}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<ReactHookFormInput
								name="additional_information"
								label="Additional Info"
								defaultValue=""
								control={control}
								size="small"
							/>
						</Grid>

						<Grid item xs={12} md={4}>
							<ReactHookFormInput
								name="name"
								label="Terminal Title*"
								defaultValue=""
								control={control}
								size="small"
								rules={{
									validate: {
										required,
										minLength: minLength(3),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<ReactHookFormInput
								name="address"
								label="Terminal Address*"
								defaultValue=""
								control={control}
								size="small"
								rules={{
									validate: {
										required,
										minLength: minLength(3),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<ReactHookFormInput
								name="color"
								label="Terminal Color"
								defaultValue=""
								control={control}
								type="color"
								size="small"
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<ReactHookFormInput
								name="city"
								label="Terminal City*"
								defaultValue=""
								control={control}
								size="small"
								rules={{
									validate: {
										required,
										minLength: minLength(3),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<ReactHookFormInput
								name="region_code"
								label="Terminal State*"
								defaultValue=""
								control={control}
								size="small"
								rules={{
									validate: {
										required,
										minLength: minLength(2),
										maxLength: maxLength(2),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<ReactHookFormInput
								name="postal_code"
								label="Terminal Zip Code*"
								defaultValue=""
								control={control}
								size="small"
								type="tel"
								rules={{
									validate: {
										required,
										minLength: minLength(3),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<ReactHookFormInput
								name="phone"
								label="Terminal Phone*"
								defaultValue=""
								control={control}
								size="small"
								type="tel"
								rules={{
									validate: {
										required,
										minLength: minLength(3),
									},
								}}
							/>
						</Grid>

						<Grid item xs={12} md={8}>
							<ReactHookFormInput
								name="note"
								label="Note"
								defaultValue=""
								control={control}
								size="small"
								multiline
								rows={4}
							/>
						</Grid>

						<Grid item xs={12} mt={2}>
							{!!id ? (
								<Button
									type="submit"
									variant="contained"
									size="large"
									sx={{ mr: 3 }}
								>
									Update Terminal
								</Button>
							) : (
								<>
									<Button
										type="submit"
										variant="contained"
										size="large"
										sx={{ mr: 3 }}
									>
										Add Terminal
									</Button>
									<Button
										variant="contained"
										size="large"
										sx={{ mr: 3 }}
										onClick={handleSubmit(
											handleOnSubmitAndMore,
										)}
									>
										Add Terminal and More
									</Button>
								</>
							)}

							<Button
								variant="outlined"
								size="large"
								component={Link}
								to="/terminals"
							>
								Cancel
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</DataContainer>
	);
};
