import React from "react";
import {
	TransactionVoidForm,
	TransactionReturnForm,
	TransactionPartialReturnForm,
	TransactionDetails,
	TransactionReportList,
} from "components";

export const Content = ({
	action,
	data,
	handleCancelClick,
	handleVoid,
	handleReturn,
	returnedAmount,
	possibleReturn,
	transactions,
}) => {
	const body = () => {
		switch (action) {
			case "void":
				return (
					<TransactionVoidForm
						cancel={handleCancelClick}
						amount={data?.total_amount}
						onSubmit={handleVoid}
					/>
				);
			case "return":
				return (
					<TransactionReturnForm
						cancel={handleCancelClick}
						amount={data?.total_amount}
						onSubmit={handleReturn}
					/>
				);
			case "partialReturn":
				return (
					<TransactionPartialReturnForm
						cancel={handleCancelClick}
						amount={data?.total_amount}
						onSubmit={handleReturn}
						returnedAmount={returnedAmount}
						possibleReturn={possibleReturn}
					/>
				);
			case "history":
				return (
					<>
						<h3>Return History</h3>
						<TransactionReportList transactions={transactions} />
					</>
				);
			default:
				return <TransactionDetails data={data} />;
		}
	};

	return body();
};
