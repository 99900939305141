import React from "react";
import { Typography, Box, Divider } from "@mui/material";
import { toFixed } from "helpers";

export const TotalBlock = ({ data = {} }) => {
	const { sale_amount, void_amount, return_amount } = data;
	const total = sale_amount - void_amount - return_amount || 0;
	return (
		<Box sx={{ textAlign: "right", mt: 2, mb: 2 }}>
			<Typography mb={1}>
				Total number of sale approved:
				<Typography component="strong" fontWeight="bold" pl={2}>
					${toFixed(sale_amount)}
				</Typography>
			</Typography>
			<Typography mb={1}>
				Total number of void approved:
				<Typography component="strong" fontWeight="bold" pl={2}>
					${toFixed(void_amount)}
				</Typography>
			</Typography>
			<Typography mb={2}>
				Total number of return approved:
				<Typography component="strong" fontWeight="bold" pl={2}>
					${toFixed(return_amount)}
				</Typography>
			</Typography>
			<Divider />
			<Typography mt={2}>
				Net Total:
				<Typography component="strong" fontWeight="bold" pl={2}>
					${toFixed(total)}
				</Typography>
			</Typography>
		</Box>
	);
};
