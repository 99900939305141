import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container, Box, Button, Grid, TextField } from "@mui/material";
import {
	ReactHookFormInput,
	ReactHookFormSelect,
	DataContainer,
} from "components";
import {
	BASE_URL,
	maxLength,
	minLength,
	required,
	emailValid,
	onlyDigits,
	SWITCHER_VALUES,
} from "helpers";

import { useHistory } from "react-router";
import { useCompany, useUser } from "queries";

export const CompanyForm = ({ onSubmit, id }) => {
	const history = useHistory();
	const [selectedFile, setSelectedFile] = useState("");

	const isUpdateMode = !!id;

	const { useGetCompanyQuery } = useCompany();
	const { useGetUserQuery } = useUser();
	const {
		data: company,
		isFetching: isCompanyFetching,
		isFetched: isCompanyFetched,
	} = useGetCompanyQuery(id);
	const {
		data: user,
		isFetching: isUserFetching,
		isFetched: isUserFetched,
	} = useGetUserQuery(company?.master_administrator_id);

	const { handleSubmit, control, reset } = useForm({
		defaultValues: {
			name: "",
			key_contact_person_name: "",
			email: "",
			address_line1: "",
			address_line2: "",
			city: "",
			region_code: "",
			postal_code: "",
			country: "United States",
			max_administrator_count: "",
			max_user_count: "",
			is_default_terminal_enable: "no",
			is_allow_blind_refunds: "no",
			timezone: "America/Los_Angeles",

			full_name: "",
			username: "",
			password: "",
		},
	});

	useEffect(() => {
		isUserFetched &&
			isCompanyFetched &&
			reset({ ...user, ...company, password: "" });
	}, [company, user, reset, isCompanyFetched, isUserFetched]);

	const toListPage = () => history.push("/companies");

	const handleOnSubmit = async values => {
		await onSubmit(values, selectedFile);
		toListPage();
	};

	const handleOnSubmitAndMore = async values => {
		await onSubmit(values, selectedFile);
		reset();
	};

	return (
		<DataContainer
			isFetching={isCompanyFetching || isUserFetching}
			isData={!!company}
		>
			<Container component="main" className="formWrapper">
				<Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
					<Grid container rowSpacing={1} columnSpacing={5}>
						<Grid item xs={12} md={6}>
							<ReactHookFormInput
								name="name"
								label="Company Name*"
								control={control}
								size="small"
								rules={{
									validate: {
										required,
										minLength: minLength(3),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<ReactHookFormInput
								name="key_contact_person_name"
								label="Key Contact Person Name*"
								control={control}
								size="small"
								rules={{ validate: { required } }}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							sx={{ display: "flex", alignItems: "flex-start" }}
						>
							{company?.image && isUpdateMode ? (
								<Box mt={2} mr={1}>
									<img
										src={`${BASE_URL}/api/companies/${company?.id}/image`}
										alt={company?.name}
										height="40px"
									/>
								</Box>
							) : (
								""
							)}
							<label
								htmlFor="button-file"
								className="labelInputFile"
							>
								<TextField
									id="button-file"
									name="logo"
									type="file"
									onChange={e =>
										setSelectedFile(e.target.files[0])
									}
									margin="normal"
									size="small"
									label="Company Image"
									fullWidth
									className={
										selectedFile === "" ? "hideText" : ""
									}
									helperText="Image Size W:220px and H:45px"
								/>

								<Button
									variant="contained"
									component="span"
									sx={{ mt: 2, ml: 2 }}
								>
									Upload
								</Button>
							</label>
						</Grid>
						<Grid item xs={12} md={6}>
							<ReactHookFormInput
								name="email"
								label="Company Email*"
								control={control}
								size="small"
								rules={{
									validate: { email: emailValid, required },
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<ReactHookFormInput
								name="address_line1"
								label="Address1*"
								control={control}
								size="small"
								rules={{ validate: { required } }}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<ReactHookFormInput
								name="address_line2"
								label="Address2"
								control={control}
								size="small"
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<ReactHookFormInput
								name="city"
								label="City*"
								control={control}
								size="small"
								rules={{
									validate: {
										required,
										minLength: minLength(3),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<ReactHookFormInput
								name="region_code"
								label="State*"
								control={control}
								size="small"
								rules={{
									validate: {
										required,
										minLength: minLength(2),
										maxLength: maxLength(2),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<ReactHookFormInput
								name="postal_code"
								label="Zip Code*"
								type="tel"
								control={control}
								size="small"
								rules={{
									validate: {
										required,
										minLength: minLength(3),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<ReactHookFormInput
								name="country"
								label="Country*"
								control={control}
								size="small"
								rules={{ validate: { required } }}
								disabled
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<ReactHookFormSelect
								name="timezone"
								label="Company Timezone*"
								control={control}
								size="small"
								variant="outlined"
								options={[
									{
										name: "Pacific Standard Time",
										value: "America/Los_Angeles",
									},
									{
										name: "Mountain Standard Time",
										value: "America/Phoenix",
									},
									{
										name: "Central Standard Time",
										value: "America/Chicago",
									},
									{
										name: "Eastern Standard Time",
										value: "America/New_York",
									},
									{
										name: "US Eastern Standard Time",
										value: "America/New_York",
									},
									{
										name: "US Mountain Standard Time",
										value: "America/Phoenix",
									},
									{
										name: "Hawaiian Standard Time",
										value: "Pacific/Honolulu",
									},
									{
										name: "Alaskan Standard Time",
										value: "America/Anchorage",
									},
								]}
								rules={{ validate: { required } }}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<ReactHookFormInput
								name="max_administrator_count"
								label="Number Of Admin Limit*"
								control={control}
								size="small"
								type="number"
								rules={{
									validate: {
										required,
										onlyDigits,
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<ReactHookFormInput
								name="max_user_count"
								label="Number Of User Limit*"
								control={control}
								size="small"
								type="number"
								rules={{
									validate: {
										required,
										onlyDigits,
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<ReactHookFormInput
								name="full_name"
								label="User Fullname*"
								control={control}
								size="small"
								rules={{
									validate: {
										required,
										minLength: minLength(3),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<ReactHookFormInput
								name="username"
								label="Username*"
								control={control}
								size="small"
								disabled={isUpdateMode}
								rules={
									isUpdateMode
										? {}
										: {
												validate: {
													required,
													minLength: minLength(3),
												},
										  }
								}
							/>
						</Grid>

						<Grid item xs={12} md={4}>
							<ReactHookFormInput
								name="password"
								label="User Password*"
								control={control}
								size="small"
								rules={
									isUpdateMode
										? {
												validate: {
													minLength: minLength(5),
													maxLength: maxLength(50),
												},
										  }
										: {
												validate: {
													required,
													minLength: minLength(5),
													maxLength: maxLength(50),
												},
										  }
								}
								helperText={
									isUpdateMode &&
									"Leave blank if you don't want to change password"
								}
							/>
						</Grid>

						<Grid item xs={12} md={4}>
							<ReactHookFormSelect
								name="is_default_terminal_enable"
								label="Is Select Terminal at Login?*"
								defaultValue=""
								control={control}
								size="small"
								variant="outlined"
								options={SWITCHER_VALUES}
								rules={{ validate: { required } }}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<ReactHookFormSelect
								name="is_allow_blind_refunds"
								label="Allow Blind Refunds*"
								defaultValue=""
								control={control}
								size="small"
								variant="outlined"
								options={SWITCHER_VALUES}
								rules={{ validate: { required } }}
							/>
						</Grid>

						<Grid item xs={12} mt={2}>
							{isUpdateMode ? (
								<Button
									type="submit"
									variant="contained"
									size="large"
									sx={{ mr: 3 }}
								>
									Update Company
								</Button>
							) : (
								<>
									<Button
										type="submit"
										variant="contained"
										size="large"
										sx={{ mr: 3 }}
									>
										Add Company
									</Button>
									<Button
										variant="contained"
										size="large"
										sx={{ mr: 3 }}
										onClick={handleSubmit(
											handleOnSubmitAndMore,
										)}
									>
										Add Company and More
									</Button>
								</>
							)}
							<Button
								variant="outlined"
								size="large"
								component={Link}
								to="/companies"
							>
								Cancel
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</DataContainer>
	);
};
