import React from "react";
import { Backdrop, CircularProgress, Box } from "@mui/material";

export const Spinner = ({ open, plane, height = 300 }) => {
	return plane ? (
		open ? (
			<Box
				sx={{
					display: "flex",
					height,
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<CircularProgress color="secondary" />
			</Box>
		) : null
	) : (
		<Backdrop
			sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
			open={open}
		>
			<CircularProgress color="secondary" />
		</Backdrop>
	);
};
