import { getSwitcherValue } from "helpers";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { userService } from "services";

export const useUser = () => {
	const [page, setPage] = useState(1);

	const useUsersListQuery = () => {
		const fetch = () => userService.getUsers({ page });

		const queryResult = useQuery(["usersList", page], fetch, {
			keepPreviousData: true,
			staleTime: 5000,
			retry: false,
		});

		return { ...queryResult, page, setPage };
	};

	const useGetUserQuery = id => {
		const fetch = () => userService.getUserData(id);

		const queryResult = useQuery(["userById", id], fetch, {
			enabled: !!id,
			retry: false,
			select: ({
				terminal_id,
				is_allow_return,
				is_allow_void,
				...data
			}) => ({
				...data,
				terminal_id: terminal_id === null ? "all" : terminal_id,
				is_allow_return: is_allow_return ? "yes" : "no",
				is_allow_void: is_allow_void ? "yes" : "no",
			}),
			onError(error) {
				//TODO: redirect to listing
				console.log(error);
			},
		});

		return { ...queryResult };
	};

	const useAddUserQuery = alert => {
		return useMutation(values => userService.addUser(values), {
			onSuccess() {
				alert.success("User has been added successfully.");
			},
			onError(error) {
				alert.error(error);
			},
		});
	};

	const useUpdateUserQuery = alert => {
		return useMutation(
			({
				terminal_id,
				is_allow_return,
				is_allow_void,
				password,
				...values
			}) =>
				userService.updateUser({
					...values,
					is_allow_return: getSwitcherValue(is_allow_return),
					is_allow_void: getSwitcherValue(is_allow_void),
					terminal_id: terminal_id === "all" ? null : terminal_id,
					password: !password ? null : password,
				}),
			{
				onSuccess() {
					alert.success("User has been updated successfully.");
				},
				onError(error) {
					alert.error(error);
				},
			},
		);
	};

	return {
		useUsersListQuery,
		useAddUserQuery,
		useGetUserQuery,
		useUpdateUserQuery,
	};
};
