import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

export const ConfirmModal = ({ onConfirm, onCancel, ...props }) => {
	const style = {
		position: "absolute",
		top: "30%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 500,
		bgcolor: "background.paper",
		boxShadow: 24,
		p: 3,
	};

	return (
		<Modal {...props}>
			<Box sx={style}>
				<Typography
					variant="h6"
					gutterBottom
					component="h3"
					textAlign="center"
				>
					Are you sure to delete this?
				</Typography>
				<Box p={2} display="flex" justifyContent="center">
					<Button
						variant="contained"
						color="primary"
						size="large"
						sx={{ minWidth: 150, mr: 2 }}
						onClick={onConfirm}
					>
						Yes
					</Button>
					<Button
						variant="outlined"
						size="large"
						onClick={onCancel}
						sx={{ minWidth: 150, ml: 2 }}
					>
						Cancel
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};
