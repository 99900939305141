import React, { useCallback, useMemo } from "react";
import { useModal } from "mui-modal-provider";
import { Button, IconButton, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { CustomTable, ConfirmModal } from "components";
import { useTerminal } from "queries";
import { getDate } from "helpers";
import { useAlert } from "react-alert";

const TerminalList = () => {
	const { showModal } = useModal();
	const alert = useAlert();
	const { useTerminalsListQuery, useTerminalDeleteQuery } = useTerminal();
	const { data, isFetching, page, setPage, refetch } =
		useTerminalsListQuery();
	const { mutateAsync: handleDeleteTerminal } = useTerminalDeleteQuery(
		alert,
		refetch,
	);

	const handlePageChange = (_event, page) => setPage(page);

	const showConfirmModal = useCallback(
		id => {
			const modal = showModal(ConfirmModal, {
				onConfirm: () => {
					handleDeleteTerminal(id);
					modal.hide();
				},
				onCancel: () => modal.hide(),
			});
		},
		[handleDeleteTerminal, showModal],
	);

	const columns = useMemo(
		() => [
			{
				Header: "Company Name",
				accessor: "company",
				Cell: ({ value }) => value?.name,
			},
			{
				Header: "Location Name",
				accessor: "location",
				Cell: ({ value }) => value?.name,
			},
			{
				Header: "Terminal ID",
				accessor: "id",
			},
			{
				Header: "Authentication Key",
				accessor: "authentication_key",
			},
			{
				Header: "Additional Info",
				accessor: "additional_information",
			},
			{
				Header: "Note",
				accessor: "note",
			},
			{
				Header: "Color",
				accessor: "color",
				Cell: ({ value }) => (
					<Box
						sx={{
							width: 20,
							height: 20,
							border: "1px solid #333",
							background: value,
						}}
					/>
				),
			},
			{
				Header: "Created Date",
				accessor: "created_at",
				Cell: ({ value }) => getDate(value),
			},
			{
				Header: "Options",
				id: "options",
				Cell: ({ row: { original } }) => (
					<Box display="flex">
						<IconButton
							color="primary"
							title="Edit Terminal"
							component={Link}
							to={`/terminals/${original?.id}`}
						>
							<EditIcon />
						</IconButton>
						<IconButton
							onClick={() => showConfirmModal(original?.id)}
							color="primary"
							title="Delete Terminal"
						>
							<DeleteIcon />
						</IconButton>
					</Box>
				),
			},
		],
		[showConfirmModal],
	);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<h2>Terminal List</h2>
				<Button
					variant="outlined"
					size="medium"
					component={Link}
					to="/terminals/add-terminal"
				>
					Add a new terminal
				</Button>
			</Box>

			<CustomTable
				columns={columns}
				data={data?.items}
				isLoading={isFetching}
				handlePageChange={handlePageChange}
				count={data?.total_count}
				pageIndex={page}
				hover
				disableSortBy={true}
			/>
		</>
	);
};

export default TerminalList;
