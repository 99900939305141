import { TextField, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";

export const ReactHookFormCurrencyInput = ({
	name,
	label,
	control,
	defaultValue,
	rules,
	decimalScale = 2,
	multiline,
	inputProps,
	...props
}) => {
	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			isNumericString
			render={({ field: { value, onChange }, fieldState: { error } }) => (
				<>
					<NumericFormat
						customInput={TextField}
						thousandSeparator=","
						onValueChange={onChange}
						value={value?.formattedValue}
						decimalScale={decimalScale}
						allowNegative={false}
						margin="normal"
						fullWidth
						multiline={multiline}
						label={label}
						inputProps={inputProps}
						error={Boolean(error)}
						{...props}
					/>
					{error && (
						<FormHelperText error>{error.message}</FormHelperText>
					)}
				</>
			)}
		/>
	);
};
