import { red, green } from "@mui/material/colors";

export const BASE_URL = process.env.REACT_APP_API_URL;

export const SWITCHER_VALUES = [
	{ name: "Yes", value: "yes" },
	{ name: "No", value: "no" },
];

export const transactionStatuses = {
	APPROVED: "approved",
	PENDING: "pending",
	ERROR: "error",
	BATCH: "no_open_batch",
	NOT_FOUND: "not_found",
	DECLINED: "declined",
	CANCELED: "canceled",
	VOIDED: "voided",
	FAILED: "failed",
};

export const failedTransactions = [
	transactionStatuses.FAILED,
	transactionStatuses.NOT_FOUND,
	transactionStatuses.PENDING,
	transactionStatuses.ERROR,
	transactionStatuses.BATCH,
	transactionStatuses.DECLINED,
	transactionStatuses.CANCELED,
];

export const TRANSACTION_SEARCH_STATUS = [
	{ name: "Approved", value: transactionStatuses.APPROVED },
	{ name: "Failed", value: failedTransactions },
];

export const TRANSACTION_STATUS = [
	{
		name: "Pending",
		value: transactionStatuses.PENDING,
		color: "error",
		refresh: true,
	},
	{
		name: "Error",
		value: transactionStatuses.ERROR,
		color: "error",
		refresh: true,
	},
	{
		name: "No open batch",
		value: transactionStatuses.BATCH,
		color: "error",
		refresh: true,
	},
	{
		name: "Approved",
		value: transactionStatuses.APPROVED,
		color: green[500],
		print: true,
	},
	{ name: "Not found", value: transactionStatuses.NOT_FOUND, color: "error" },
	{ name: "Declined", value: transactionStatuses.DECLINED, color: "error" },
	{ name: "Canceled", value: transactionStatuses.CANCELED, color: "error" },
	{
		name: "Voided",
		value: transactionStatuses.VOIDED,
		color: "warning",
		refresh: true,
	},
	{
		name: "Failed",
		value: transactionStatuses.FAILED,
		color: "error",
		refresh: true,
	},
];

export const canPrint = value =>
	TRANSACTION_STATUS.find(status => status.value === value)?.print;

export const canRefresh = value =>
	TRANSACTION_STATUS.find(status => status.value === value)?.refresh;

export const transactionTypes = {
	SALE: "sale",
	RETURN: "return",
	VOID: "void",
};

export const USER_EMPTY_OPTION = { name: "Select User", value: "" };
export const TERMINAL_EMPTY_OPTION = { name: "Select Terminal", value: "" };
export const LOCATION_EMPTY_OPTION = { name: "Select Location", value: "" };

export const iconMenuStyle = {
	backgroundColor: "secondary.main",
	color: red[200],
};
