import {
	Select,
	InputLabel,
	FormControl,
	MenuItem,
	FormHelperText,
} from "@mui/material";
import { Controller } from "react-hook-form";

export const ReactHookFormSelect = ({
	name,
	label,
	control,
	defaultValue,
	children,
	options,
	rules,
	customOnChange,
	margin = "normal",
	...props
}) => {
	const labelId = `${name}-label`;
	return (
		<FormControl fullWidth {...props} margin={margin}>
			<InputLabel id={labelId}>{label}</InputLabel>
			<Controller
				render={({
					field: { onChange, value },
					fieldState: { error },
				}) => (
					<>
						<Select
							labelId={labelId}
							label={label}
							onChange={e => {
								onChange(e);
								customOnChange?.(e);
							}}
							value={value}
							error={Boolean(error)}
						>
							{options.map(({ value, name }) => (
								<MenuItem value={value} key={value}>
									{name}
								</MenuItem>
							))}
						</Select>
						{error && (
							<FormHelperText error>
								{error.message}
							</FormHelperText>
						)}
					</>
				)}
				name={name}
				control={control}
				defaultValue={defaultValue}
				rules={rules}
			/>
		</FormControl>
	);
};
