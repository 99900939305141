import { axios } from "helpers";

export function handleResponse(response) {
	return Promise.resolve(response.data);
}

export function handleError(error, errorHandler) {
	if (errorHandler) {
		errorHandler(error);
	}

	const errorText =
		error?.response?.data?.error_description ||
		error?.response?.data?.title ||
		error?.response?.statusText ||
		"Something went wrong. Please try again later.";

	return Promise.reject(errorText);
}

export const authHeader = contentType => {
	const currentUser = localStorage.getItem("currentUser");
	if (currentUser) {
		const { access_token } = JSON.parse(currentUser);
		let headers = {
			Authorization: "Bearer " + access_token,
		};
		if (contentType) {
			headers["Content-Type"] = contentType;
		}

		return headers;
	} else {
		return {};
	}
};

export async function axiosWithHandler(options) {
	if (!options) {
		options = {};
	}
	return axios(options).then(handleResponse).catch(handleError);
}
