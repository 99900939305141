import { axiosWithHandler } from "helpers";

export const currentLocationService = {
	getLocations,
};

const PAGE_SIZE = 40;

function getLocations({ page, page_size }) {
	const options = {
		method: "get",
		url: "/api/companies/current/locations",
		params: {
			page_index: page - 1,
			page_size: page_size || PAGE_SIZE,
		},
	};

	return axiosWithHandler(options);
}
