import React from "react";
import { TerminalForm } from "components";
import { useAlert } from "react-alert";
import { useTerminal } from "queries";

const AddTerminal = () => {
	const alert = useAlert();
	const { useAddTerminalQuery } = useTerminal();
	const { mutateAsync: handleAddLocation } = useAddTerminalQuery(alert);

	return (
		<>
			<h2>Create a new terminal</h2>
			<TerminalForm onSubmit={handleAddLocation} />
		</>
	);
};
export default AddTerminal;
