import React from "react";
import { Link } from "react-router-dom";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Divider,
	Grid,
	Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	getDate,
	getRoleTitle,
	toFixed,
	toUpperCase,
	TRANSACTION_STATUS,
} from "helpers";

export const TransactionDetails = ({ data }) => {
	return (
		<>
			<Grid container spacing={2} mb={2}>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>Id: </strong>
						{data?.id}
					</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>Transaction Type: </strong>
						{toUpperCase(data?.type)}
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={2} mb={2}>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>Reference Id: </strong>
						{data?.reference_id === data?.id ? (
							data?.reference_id
						) : (
							<Typography
								gutterBottom
								component={Link}
								to={`/transactions/${data?.reference_id}`}
							>
								{data?.reference_id}
							</Typography>
						)}
					</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>Transaction Amount: </strong>$
						{toFixed(data?.total_amount)}
					</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>Transaction Status: </strong>
						{data?.status &&
							TRANSACTION_STATUS.map((item, index) =>
								item.value === data?.status ? (
									<Typography
										fontWeight="500"
										key={index}
										color={item.color}
										component="span"
									>
										{item.name}
									</Typography>
								) : (
									""
								),
							)}
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={2} mb={2}>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>Reference Number: </strong>
						{data?.reference_number}
					</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>Terminal Id: </strong>
						{data?.terminal_note}
					</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>Transaction Date: </strong>
						{data?.created_at && getDate(data?.created_at)}
					</Typography>
				</Grid>
			</Grid>
			<Grid item xs={12} mb={4} mt={4}>
				<Divider />
			</Grid>
			<Grid container spacing={2} mb={2}>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>Initiated By: </strong>
						{data?.user?.full_name}
					</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>Username: </strong>
						{data?.user?.username}
					</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>User Type: </strong>
						{getRoleTitle(data?.user?.role)}
					</Typography>
				</Grid>
			</Grid>
			<Grid item xs={12} mb={4} mt={4}>
				<Divider />
			</Grid>
			<Grid container spacing={2} mb={2}>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>Result Code: </strong>
						{data?.response?.result_code}
					</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>Message: </strong>
						{decodeURIComponent(data?.response?.message || "")}
					</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>Auth Code: </strong>
						{data?.response?.auth_code}
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={2} mb={2}>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>Print reference: </strong>
						{data?.response?.pnref}
					</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>SN: </strong>
						{data?.response?.sn}
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={2} mb={2}>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>Cardholder Name:</strong>
					</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						{decodeURIComponent(
							data?.credit_card?.card_holder || "",
						)}
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={2} mb={2}>
				<Grid item xs={12} md={4}>
					<Typography gutterBottom component="div">
						<strong>Signature:</strong>
					</Typography>
				</Grid>
				{data?.response?.signature && (
					<Grid item xs={12} md={4}>
						<img
							alt={data?.credit_card?.card_holder}
							src={`data:image/bitmap;base64,${data?.response?.signature}`}
						/>
					</Grid>
				)}
			</Grid>
			<Grid container spacing={2} mb={2}>
				<Grid item xs={12} md={12}>
					<Typography gutterBottom component="div">
						<strong>EMV data:</strong>
					</Typography>
				</Grid>
				<Grid item xs={12} md={12}>
					<Typography
						gutterBottom
						component="div"
						sx={{ wordBreak: "break-all" }}
					>
						{data?.response?.emv_data}
					</Typography>
				</Grid>
			</Grid>
			{data?.response?.ext_data && (
				<Grid container spacing={2} mb={2} mt={2}>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography gutterBottom component="div">
								<strong>Ext data:</strong>
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								gutterBottom
								component="div"
								sx={{ wordBreak: "break-all" }}
							>
								{decodeURIComponent(
									data?.response?.ext_data || "",
								)}
							</Typography>
						</AccordionDetails>
					</Accordion>
				</Grid>
			)}
		</>
	);
};
