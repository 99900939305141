import { queryClient } from "helpers";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { locationService } from "services";

export const useLocation = () => {
	const [page, setPage] = useState(1);
	const [companyId, setCompanyId] = useState(null);

	const useLocationsListQuery = () => {
		const fetch = () =>
			locationService.getLocations({
				page,
			});

		const queryResult = useQuery(["locationsList", page], fetch, {
			keepPreviousData: true,
			staleTime: 5000,
			retry: false,
		});

		return { ...queryResult, page, setPage };
	};

	const useGetLocationQuery = id => {
		const fetch = () => locationService.getLocationData(id);

		const queryResult = useQuery(["location", id], fetch, {
			enabled: !!id,
			retry: false,
		});

		return { ...queryResult };
	};

	const useDeleteLocationQuery = alert => {
		return useMutation(id => locationService.deleteLocation(id), {
			async onSuccess() {
				alert.success("Location has been deleted successfully.");
				await queryClient.refetchQueries(["locationsList", page]);
			},
			onError(error) {
				alert.error(error);
			},
		});
	};

	const useAddLocationQuery = alert => {
		return useMutation(values => locationService.addLocation(values), {
			onSuccess() {
				alert.success("Location has been added successfully.");
			},
			onError(error) {
				alert.error(error);
			},
		});
	};

	const useUpdateLocationQuery = (id, alert) => {
		return useMutation(
			values => locationService.updateLocation(values, id),
			{
				onSuccess() {
					alert.success("Location has been updated successfully.");
				},
				onError(error) {
					alert.error(error);
				},
			},
		);
	};

	const useAddLocationImageQuery = alert => {
		return useMutation(
			({ id, file }) => locationService.addLocationImage(id, file),
			{
				onError(error) {
					alert.error(error);
				},
			},
		);
	};

	const useLocationsSelectQuery = () => {
		const fetch = () =>
			locationService.getLocations({
				page: 1,
				company_id: companyId,
				page_size: 500,
			});

		const queryResult = useQuery(["locationsDropdown", companyId], fetch, {
			retry: false,
			enabled: !!companyId,
			select: ({ items }) =>
				items.map(({ id, name }) => ({
					value: id,
					name: name,
				})),
		});

		return { ...queryResult, setCompanyId };
	};

	return {
		useLocationsListQuery,
		useGetLocationQuery,
		useDeleteLocationQuery,
		useAddLocationQuery,
		useUpdateLocationQuery,
		useAddLocationImageQuery,
		useLocationsSelectQuery,
	};
};
