import { BehaviorSubject } from "rxjs";
import { axios, authHeader, history } from "helpers";

export const currentUserSubject = new BehaviorSubject(
	JSON.parse(localStorage.getItem("currentUser")),
);

export const authenticationService = {
	login,
	logout,
};

function login({ username, password, alert }) {
	return axios({
		method: "post",
		url: "/api/token",
		data: {
			username,
			password,
			grant_type: "password",
		},
	})
		.then(async result => {
			await localStorage.setItem(
				"currentUser",
				JSON.stringify(result.data),
			);
			const currentUser = localStorage.getItem("currentUser");
			const { access_token } = JSON.parse(currentUser);
			axios.defaults.headers.common["Authorization"] = currentUser
				? `Bearer ${access_token}`
				: "";

			return Promise.resolve(result.data);
		})
		.catch(error => {
			history.push("/login");
			alert.error(error?.response?.data?.error_description);
			return Promise.reject(error);
		});
}

function logout() {
	const currentUser = localStorage.getItem("currentUser");

	const headers = authHeader();
	if (currentUser) {
		const { refresh_token } = JSON.parse(currentUser);
		axios({
			method: "post",
			url: "/api/revoke_token",
			headers,
			data: {
				refresh_token,
			},
		});
	}
	// remove user from local storage to log user out
	// history.push("/login");
	localStorage.removeItem("currentUser");
	localStorage.removeItem("userData");
	localStorage.removeItem("terminal");
}
