import React from "react";
import { LocationForm } from "components";
import { useAlert } from "react-alert";
import { useLocation } from "queries";
import { useParams } from "react-router";

const UpdateLocation = () => {
	const { id } = useParams();
	const alert = useAlert();
	const { useUpdateLocationQuery, useAddLocationImageQuery } = useLocation();
	const { mutateAsync: handleAddLocationImage } =
		useAddLocationImageQuery(alert);

	const { mutateAsync: handleUpdateLocation } = useUpdateLocationQuery(
		id,
		alert,
	);

	const handleOnSubmit = async (values, selectedFile) => {
		await handleUpdateLocation(values);
		selectedFile &&
			(await handleAddLocationImage({ id, file: selectedFile }));
	};

	return (
		<>
			<h2>Update location</h2>
			<LocationForm onSubmit={handleOnSubmit} id={id} />
		</>
	);
};
export default UpdateLocation;
