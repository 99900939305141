import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { getDate, toFixed } from "helpers";

export const DuplicateTransactionModal = ({
	onConfirm,
	onCancel,
	data,
	...props
}) => {
	const style = {
		position: "absolute",
		top: "30%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 450,
		bgcolor: "background.paper",
		boxShadow: 24,
		p: 3,
	};

	return (
		<Modal {...props}>
			<Box sx={style}>
				<Typography
					variant="h6"
					gutterBottom
					component="h3"
					textAlign="center"
				>
					Possible duplicate transaction
				</Typography>
				<Typography variant="body1" component="p">
					We found another transaction for reference number{" "}
					{data?.reference_number} at{" "}
					{getDate(data?.created_at, "h:mm A")} today.
				</Typography>
				<Typography variant="body1" component="p">
					It was processed on terminal{" "}
					<strong>{data?.terminal_note}</strong> for{" "}
					<strong>${toFixed(data?.total_amount)}</strong>
				</Typography>
				<Typography variant="body1" component="p">
					Are you sure you want to proceed with this sale?
				</Typography>
				<Box mt={5} display="flex" justifyContent="space-between">
					<Button
						variant="outlined"
						onClick={onCancel}
						sx={{ minWidth: 150 }}
					>
						No, don't process
					</Button>
					<Button
						variant="contained"
						color="primary"
						sx={{ minWidth: 150 }}
						onClick={onConfirm}
					>
						I'm sure, continue!
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};
