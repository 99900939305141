import { TextField, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";

export const ReactHookFormDatePicker = ({
	name,
	label,
	control,
	defaultValue,
	children,
	options,
	rules,
	isLoading,
	rows,
	size,
	margin = "normal",
	...props
}) => {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			rules={rules}
			render={({
				field: { onChange, value },
				fieldState: { error: formError },
			}) => (
				<>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							label={label}
							value={value}
							onChange={onChange}
							renderInput={params => (
								<TextField
									size={size}
									margin={margin}
									{...params}
									error={Boolean(formError)}
									className="datePicker"
								/>
							)}
							{...props}
						/>
					</LocalizationProvider>

					{formError && (
						<FormHelperText error>
							{formError.message}
						</FormHelperText>
					)}
				</>
			)}
		/>
	);
};
