import React, { useRef } from "react";
import { useAlert } from "react-alert";
import { Button, FormHelperText } from "@mui/material";
import { useForm } from "react-hook-form";
import { Box } from "@mui/system";
import { ReactHookFormInput } from "components";
import { useCurrentUser } from "queries";
import { maxLength, minLength, required, passwordConfirmation } from "helpers";

const ChangePassword = () => {
	const alert = useAlert();
	const { useChangePasswordQuery } = useCurrentUser();
	const {
		mutateAsync: handleUpdateUser,
		isLoading,
		error,
	} = useChangePasswordQuery(alert);

	const { handleSubmit, control, watch, reset } = useForm();
	const password = useRef({});
	password.current = watch("new_password", "");

	const onSubmit = async ({ current_password, new_password }) =>
		handleUpdateUser({ current_password, new_password }).then(() =>
			reset({
				current_password: "",
				new_password: "",
				new_password_confirm: "",
			}),
		);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<h2>Change Password</h2>
			</Box>

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					component="form"
					onSubmit={handleSubmit(onSubmit)}
					sx={{ mt: 1, maxWidth: 700 }}
				>
					<ReactHookFormInput
						name="current_password"
						label="Current Password*"
						type="password"
						control={control}
						autoFocus
						size="large"
						isLoading={isLoading}
						defaultValue=""
						rules={{
							validate: {
								required,
								minLength: minLength(5),
								maxLength: maxLength(100),
							},
						}}
					/>
					<ReactHookFormInput
						name="new_password"
						label="New Password*"
						type="password"
						control={control}
						size="large"
						isLoading={isLoading}
						defaultValue=""
						rules={{
							validate: {
								required,
								minLength: minLength(5),
								maxLength: maxLength(100),
							},
						}}
					/>
					<ReactHookFormInput
						name="new_password_confirm"
						label="Confirm New Password*"
						type="password"
						control={control}
						size="large"
						isLoading={isLoading}
						defaultValue=""
						rules={{
							validate: {
								required,
								minLength: minLength(5),
								maxLength: maxLength(100),
								passwordConfirmation: value =>
									passwordConfirmation(
										value,
										password.current,
									),
							},
						}}
					/>
					{error && <FormHelperText error>{error}</FormHelperText>}
					<Button
						type="submit"
						variant="contained"
						size="large"
						sx={{ mt: 3, mb: 2 }}
					>
						Submit
					</Button>
				</Box>
			</Box>
		</>
	);
};
export default ChangePassword;
