import { TextField, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";

export const ReactHookFormInput = ({
	name,
	label,
	control,
	defaultValue,
	children,
	options,
	rules,
	isLoading,
	multiline,
	rows,
	inputProps,
	...props
}) => {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			rules={rules}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<>
					<TextField
						label={label}
						onChange={onChange}
						value={value}
						margin="normal"
						fullWidth
						error={Boolean(error)}
						multiline={multiline}
						rows={rows}
						inputProps={inputProps}
						{...props}
					/>

					{error && (
						<FormHelperText error>{error.message}</FormHelperText>
					)}
				</>
			)}
		/>
	);
};
