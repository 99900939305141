import React, { useCallback, useMemo } from "react";
import { Button, IconButton, Box, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { CustomTable } from "components";
import { useCurrentUser } from "queries";
import { getDate, getRoleTitle, useLocalStorage } from "helpers";
import { useAlert } from "react-alert";

const UserList = () => {
	const [userData] = useLocalStorage("userData", {});

	const alert = useAlert();
	const {
		useCurrentUsersListQuery,
		useActivateUserQuery,
		useDeactivateUserQuery,
	} = useCurrentUser();
	const { data, isFetching, page, setPage } = useCurrentUsersListQuery();
	const { mutateAsync: activateUser } = useActivateUserQuery(alert);
	const { mutateAsync: deactivateUser } = useDeactivateUserQuery(alert);

	const handlePageChange = (_event, page) => setPage(page);

	const handleActivateUser = useCallback(
		(id, status) => (status ? deactivateUser(id) : activateUser(id)),
		[activateUser, deactivateUser],
	);

	const columns = useMemo(
		() => [
			{
				Header: "User ID",
				accessor: "id",
			},
			{
				Header: "Username",
				accessor: "username",
			},
			{
				Header: "User Fullname",
				accessor: "full_name",
			},
			{
				Header: "Allowed Terminal",
				accessor: "terminal_id",
				Cell: ({ value }) => (value === null ? "Any" : value),
			},
			{
				Header: "User Type",
				accessor: "role",
				Cell: ({ value }) => getRoleTitle(value),
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: ({ value }) => {
					return value ? (
						<Typography fontWeight="500" color={green[500]}>
							Active
						</Typography>
					) : (
						<Typography fontWeight="500" color="error">
							Suspended
						</Typography>
					);
				},
			},
			{
				Header: "Created Date",
				accessor: "created_at",
				Cell: ({ value }) => getDate(value),
			},
			{
				Header: "Options",
				id: "options",
				Cell: ({
					row: {
						original: { id, status },
					},
				}) => {
					const isCurrentUser = userData?.id === id;
					return isCurrentUser ? null : (
						<Box display="flex">
							<IconButton
								color="primary"
								title="Edit user"
								component={Link}
								to={`/users/${id}`}
							>
								<EditIcon />
							</IconButton>

							<IconButton
								onClick={() => handleActivateUser(id, status)}
								color={status ? "error" : "primary"}
								title={status ? "Deactivate" : "Activate"}
							>
								{status ? <ToggleOffIcon /> : <ToggleOnIcon />}
							</IconButton>
						</Box>
					);
				},
			},
		],
		[handleActivateUser, userData?.id],
	);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<h2>Users List</h2>
				<Button
					variant="outlined"
					size="medium"
					component={Link}
					to="/users/add-user"
				>
					Add a new user
				</Button>
			</Box>

			<CustomTable
				columns={columns}
				data={data?.items}
				isLoading={isFetching}
				handlePageChange={handlePageChange}
				count={data?.total_count}
				pageIndex={page}
				hover
				disableSortBy={true}
			/>
		</>
	);
};

export default UserList;
