import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container, Box, Button, Grid, TextField } from "@mui/material";
import {
	ReactHookFormInput,
	ReactHookFormSelect,
	DataContainer,
} from "components";
import { BASE_URL, maxLength, minLength, required } from "helpers";

import { useHistory } from "react-router";
import { useCompany, useLocation } from "queries";

export const LocationForm = ({ onSubmit, id }) => {
	const history = useHistory();
	const [selectedFile, setSelectedFile] = useState("");

	const isUpdateMode = !!id;

	const { useGetLocationQuery } = useLocation();
	const { data, isFetching } = useGetLocationQuery(id);

	const { useCompaniesSelectQuery } = useCompany();
	const { data: companies, isFetching: isCompaniesFetching } =
		useCompaniesSelectQuery();

	const { handleSubmit, control, reset } = useForm({
		defaultValues: {
			company_id: 1 || "",
			name: "",
			address_line1: "",
			address_line2: "",
			city: "",
			region_code: "",
			postal_code: "",
			phone: "",
		},
	});

	useEffect(() => reset(data), [data, reset]);

	const toListPage = () => history.push("/locations");

	const handleOnSubmit = async values => {
		await onSubmit(values, selectedFile);
		toListPage();
	};

	const handleOnSubmitAndMore = async values => {
		await onSubmit(values, selectedFile);
		reset();
	};

	return (
		<DataContainer isFetching={isFetching || isCompaniesFetching}>
			<Container component="main" className="formWrapper">
				<Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
					<Grid container rowSpacing={1} columnSpacing={5}>
						<Grid item xs={12} md={6}>
							<ReactHookFormSelect
								name="company_id"
								label="Select Company*"
								defaultValue=""
								control={control}
								size="small"
								variant="outlined"
								options={companies || []}
								rules={{ validate: { required } }}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							{data?.image && isUpdateMode ? (
								<img
									src={`${BASE_URL}/api/locations/${data?.id}/image`}
									alt={data?.name}
									height="40px"
								/>
							) : (
								""
							)}
						</Grid>
						<Grid item xs={12} md={6}>
							<ReactHookFormInput
								name="name"
								label="Location Name*"
								control={control}
								size="small"
								defaultValue=""
								rules={{
									validate: {
										required,
										minLength: minLength(3),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<label
								htmlFor="contained-button-file"
								className="labelInputFile"
							>
								<TextField
									id="contained-button-file"
									name="logo"
									type="file"
									onChange={e =>
										setSelectedFile(e.target.files[0])
									}
									margin="normal"
									size="small"
									label="Location Image"
									fullWidth
									className={
										selectedFile === "" ? "hideText" : ""
									}
								/>

								<Button
									variant="contained"
									component="span"
									sx={{ mt: 2, ml: 2 }}
								>
									Upload
								</Button>
							</label>
						</Grid>
						<Grid item xs={12} md={6}>
							<ReactHookFormInput
								name="address_line1"
								label="Location Address1*"
								defaultValue=""
								control={control}
								size="small"
								rules={{
									validate: {
										required,
										minLength: minLength(3),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<ReactHookFormInput
								name="address_line2"
								label="Location Address2"
								defaultValue=""
								control={control}
								size="small"
								rules={{
									validate: { minLength: minLength(3) },
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<ReactHookFormInput
								name="city"
								label="Location City*"
								defaultValue=""
								control={control}
								size="small"
								rules={{
									validate: {
										required,
										minLength: minLength(3),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<ReactHookFormInput
								name="region_code"
								label="Location State*"
								defaultValue=""
								control={control}
								size="small"
								rules={{
									validate: {
										required,
										minLength: minLength(2),
										maxLength: maxLength(2),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<ReactHookFormInput
								name="postal_code"
								label="Location Zip Code*"
								defaultValue=""
								control={control}
								type="tel"
								size="small"
								rules={{
									validate: {
										required,
										minLength: minLength(3),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<ReactHookFormInput
								name="phone"
								label="Location Phone*"
								defaultValue=""
								control={control}
								size="small"
								type="tel"
								rules={{
									validate: {
										required,
										minLength: minLength(3),
									},
								}}
							/>
						</Grid>

						<Grid item xs={12} mt={2}>
							{isUpdateMode ? (
								<Button
									type="submit"
									variant="contained"
									size="large"
									sx={{ mr: 3 }}
								>
									Update Location
								</Button>
							) : (
								<>
									<Button
										type="submit"
										variant="contained"
										size="large"
										sx={{ mr: 3 }}
									>
										Add Location
									</Button>
									<Button
										variant="contained"
										size="large"
										sx={{ mr: 3 }}
										onClick={handleSubmit(
											handleOnSubmitAndMore,
										)}
									>
										Add Location and More
									</Button>
								</>
							)}
							<Button
								variant="outlined"
								size="large"
								component={Link}
								to="/locations"
							>
								Cancel
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</DataContainer>
	);
};
