import moment from "moment";
import { authenticationService } from "services";

export const logout = history => {
	authenticationService.logout();
	history?.push("/login");
};

export const getDate = (date, format = "MM/DD/YYYY") =>
	moment(date).format(format);

export const intersectionOfArrays = (arr1, arr2) => {
	return arr1
		.filter(element => arr2.indexOf(element) !== -1)
		.filter((element, pos, self) => self.indexOf(element) === pos);
};

export const toUpperCase = (string = "") =>
	string && string[0].toUpperCase() + string.slice(1);

export const toFixed = (value = 0, amount = 2) =>
	parseFloat(value).toFixed(amount);

export const getSwitcherValue = value => (value === "no" ? false : true);

export const formDataToObject = (formDataString = "") => {
	const array = formDataString?.split(",").map(item => item?.split("="));
	const entries = new Map(array);
	return Object.fromEntries(entries);
};
