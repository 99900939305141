import React, { useCallback, useMemo } from "react";
import { useModal } from "mui-modal-provider";
import { Button, IconButton, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { CustomTable, ConfirmModal } from "components";
import { useLocation } from "queries";
import { getDate, BASE_URL } from "helpers";
import { useAlert } from "react-alert";

const LocationList = () => {
	const { showModal } = useModal();
	const alert = useAlert();
	const { useLocationsListQuery, useDeleteLocationQuery } = useLocation();
	const { data, isFetching, page, setPage } = useLocationsListQuery();
	const { mutateAsync: handleDeleteLocation } = useDeleteLocationQuery(alert);

	const handlePageChange = (_event, page) => setPage(page);

	const showConfirmModal = useCallback(
		id => {
			const modal = showModal(ConfirmModal, {
				onConfirm: () => {
					handleDeleteLocation(id);
					modal.hide();
				},
				onCancel: () => modal.hide(),
			});
		},
		[handleDeleteLocation, showModal],
	);

	const columns = useMemo(
		() => [
			{
				Header: "Company ID",
				accessor: "id",
			},
			{
				Header: "Company Name",
				accessor: "company",
				Cell: ({ value }) => value?.name,
			},
			{
				Header: "Location Name",
				accessor: "name",
			},
			{
				Header: "Location Image",
				accessor: "image",
				Cell: ({ row, value }) =>
					value ? (
						<img
							src={`${BASE_URL}/api/locations/${row.original.id}/image`}
							alt={row.original.name}
							width="40px"
						/>
					) : (
						""
					),
			},
			{
				Header: "Location Phone",
				accessor: "phone",
			},
			{
				Header: "Created Date",
				accessor: "created_at",
				Cell: ({ value }) => getDate(value),
			},
			{
				Header: "Options",
				id: "options",
				Cell: ({ row: { original } }) => (
					<Box display="flex">
						<IconButton
							color="primary"
							title="Edit Location"
							component={Link}
							to={`/locations/${original?.id}`}
						>
							<EditIcon />
						</IconButton>
						<IconButton
							onClick={() => showConfirmModal(original?.id)}
							color="primary"
							title="Delete Location"
						>
							<DeleteIcon />
						</IconButton>
					</Box>
				),
			},
		],
		[showConfirmModal],
	);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<h2>Location List</h2>
				<Button
					variant="outlined"
					size="medium"
					component={Link}
					to="/locations/add-location"
				>
					Add a new location
				</Button>
			</Box>

			<CustomTable
				columns={columns}
				data={data?.items}
				isLoading={isFetching}
				handlePageChange={handlePageChange}
				count={data?.total_count}
				pageIndex={page}
				hover
				disableSortBy={true}
			/>
		</>
	);
};

export default LocationList;
