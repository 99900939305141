import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Box, Button, Grid, Divider, Typography } from "@mui/material";
import { logout, required, useLocalStorage } from "helpers";
import { ReactHookFormSelect } from "components";
import { useCurrentLocation, useCurrentTerminal } from "queries";

export const SelectTerminalForm = ({ onSubmitForm }) => {
	const history = useHistory();
	const [terminal, setTerminal] = useLocalStorage("terminal", "");
	const { handleSubmit, control, watch, setValue } = useForm({
		mode: "onChange",
		defaultValues: terminal || {
			location_id: "",
			terminal_id: "",
		},
	});

	const location_id = watch("location_id");

	const { useLocationsSelectQuery } = useCurrentLocation();
	const { data: locations = [], isFetching: isLocationsFetching } =
		useLocationsSelectQuery();

	const { useTerminalsSelectQuery } = useCurrentTerminal();
	const { data: terminals = [], isFetching: isTerminalsFetching } =
		useTerminalsSelectQuery(location_id);

	const handleLogout = () => logout(history);

	const onSubmit = values => {
		const terminal_name =
			terminals.find(terminal => terminal.value === values.terminal_id)
				?.name || "";
		setTerminal({ ...values, terminal_name });
		onSubmitForm?.();
	};

	const handleTerminalChange = () => setValue("terminal_id", "");

	return (
		<>
			<Typography variant="h5" mb={2} pl={2} pr={2}>
				Please select a terminal
			</Typography>

			<Divider />

			<Box component="form" onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={2} px={2} py={4}>
					<Grid item xs={12} md={6}>
						<ReactHookFormSelect
							name="location_id"
							label="Select a location"
							control={control}
							variant="outlined"
							options={locations}
							rules={{ validate: { required } }}
							customOnChange={handleTerminalChange}
							disabled={isLocationsFetching}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<ReactHookFormSelect
							name="terminal_id"
							label="Select a terminal"
							control={control}
							variant="outlined"
							options={terminals}
							rules={{ validate: { required } }}
							disabled={isTerminalsFetching}
						/>
					</Grid>
				</Grid>
				<Divider />
				<Box p={2} display="flex" justifyContent="space-between">
					<Button type="submit" variant="contained" size="large">
						Go Ahead
					</Button>
					<Button
						variant="outlined"
						size="large"
						onClick={handleLogout}
					>
						Nevermind, log me out
					</Button>
				</Box>
			</Box>
		</>
	);
};
