import React from "react";
import { Box, Typography } from "@mui/material";

export const EmptyBox = ({ height = 100, text = "There are no data" }) => (
	<Box
		sx={{
			display: "flex",
			height,
			justifyContent: "center",
			alignItems: "center",
		}}
	>
		<Typography
			variant="h6"
			component="div"
			sx={{ fontStyle: "italic", color: "GrayText" }}
		>
			{text}
		</Typography>
	</Box>
);
