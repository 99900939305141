import React from "react";

import { Spinner, EmptyBox } from "components";

export const DataContainer = ({
	isFetching,
	isData = true,
	children,
	height,
}) =>
	isFetching ? (
		<Spinner open={isFetching} plane height={height} />
	) : !isData ? (
		<EmptyBox />
	) : (
		children
	);
