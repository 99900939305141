import axios from "axios";
import { BASE_URL } from "helpers";
import qs from "qs";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
	failedQueue.forEach(prom => {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve(token);
		}
	});

	failedQueue = [];
};

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" }),
});

axiosInstance.interceptors.request.use(
	async config => {
		const currentUser = localStorage.getItem("currentUser");
		if (currentUser) {
			const { access_token } = JSON.parse(currentUser);

			config.headers = {
				Authorization: `Bearer ${access_token}`,
				"Content-Type": "application/json",
			};
		}
		return config;
	},
	error => {
		Promise.reject(error);
	},
);

axiosInstance.interceptors.response.use(
	config => config,
	error => {
		const originalRequest = error.config;
		/* eslint eqeqeq: 0 */
		if (error?.code != "ECONNABORTED" && error?.response?.status === 401) {
			const currentUser = localStorage.getItem("currentUser");

			if (!originalRequest._retry && currentUser) {
				if (isRefreshing) {
					return new Promise(function (resolve, reject) {
						failedQueue.push({ resolve, reject });
					})
						.then(token => {
							originalRequest.headers["Authorization"] =
								"Bearer " + token;
							return axios(originalRequest);
						})
						.catch(err => {
							return Promise.reject(err);
						});
				}

				originalRequest._retry = true;
				isRefreshing = true;

				return new Promise(function (resolve, reject) {
					const { refresh_token } = JSON.parse(currentUser);

					const options = {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							grant_type: "refresh_token",
							refresh_token,
						}),
					};
					fetch(`${BASE_URL}/api/token`, options)
						.then(response => {
							response.text().then(text => {
								const userData = JSON.parse(text);
								localStorage.setItem(
									"currentUser",
									JSON.stringify(userData),
								);
								axiosInstance.defaults.headers.common[
									"Authorization"
								] = "Bearer " + userData.access_token;
								originalRequest.headers["Authorization"] =
									"Bearer " + userData.access_token;
								processQueue(null, userData.access_token);
								resolve(axios(originalRequest));
							});
						})
						.catch(err => {
							processQueue(err, null);
							reject(err);
						})
						.then(() => {
							isRefreshing = false;
						});
				});
			}
		}
		return Promise.reject(error);
	},
);

axiosInstance.interceptors.response.use(
	result => result,
	error => {
		if (error?.request?.status === 403) {
			//TODO: redirect to 404 page
			console.log(
				"403",
				error.response.data || error.response.statusText,
			);
			return Promise.reject(
				error.response.data || error.response.statusText,
			);
		}

		return Promise.reject(error);
	},
);

axiosInstance.interceptors.response.use(
	result => result,
	error => {
		if (error?.request?.status?.toString().startsWith("5")) {
			return Promise.reject({
				message: "No connection to server",
			});
		}

		return Promise.reject(error);
	},
);

export { axiosInstance as axios };
