import { axiosWithHandler } from "helpers";

export const userService = {
	getUsers,
	getUserData,
	addUser,
	updateUser,
};

const PAGE_SIZE = 40;

function getUsers({ page }) {
	const options = {
		method: "get",
		url: "/api/users",
		params: {
			page_index: page - 1,
			page_size: PAGE_SIZE,
		},
	};

	return axiosWithHandler(options);
}

function getUserData(id) {
	const options = {
		method: "get",
		url: `/api/users/${id}`,
	};

	return axiosWithHandler(options);
}

function addUser(data) {
	const options = {
		method: "post",
		url: "/api/users",
		data,
	};

	return axiosWithHandler(options);
}

function updateUser({ master_administrator_id, ...data }) {
	const options = {
		method: "put",
		url: `/api/users/${master_administrator_id}`,
		data,
	};

	return axiosWithHandler(options);
}
