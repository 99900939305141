import React, { useRef } from "react";
import { CSVLink } from "react-csv";
import { Box } from "@mui/material";
import { ExportListFilter, Spinner } from "components";
import {
	useCurrentLocation,
	useCurrentTerminal,
	useCurrentUser,
	useTransaction,
} from "queries";
import {
	LOCATION_EMPTY_OPTION,
	TERMINAL_EMPTY_OPTION,
	USER_EMPTY_OPTION,
} from "helpers";

const ExportList = ({ withoutTitle }) => {
	const csvLink = useRef();
	const { useDownloadCsvQuery } = useTransaction();
	const {
		data: csv,
		isFetching: isFetchingCsv,
		refetch: downloadCsv,
		setParams,
	} = useDownloadCsvQuery();

	const { useUsersSelectQuery } = useCurrentUser();
	const { data: users = [], isFetching: isUsersFetching } =
		useUsersSelectQuery();

	const { useTerminalsSelectQuery } = useCurrentTerminal();
	const { data: terminals = [], isFetching: isTerminalsFetching } =
		useTerminalsSelectQuery();

	const { useLocationsSelectQuery } = useCurrentLocation();
	const { data: locations = [], isFetching: isLocationsFetching } =
		useLocationsSelectQuery();

	const handleDownloadCsv = async values => {
		const correctedValues = {
			...values,
			total_amount: values.total_amount.value,
		};

		await setParams(prevState => ({ ...prevState, ...correctedValues }));
		await downloadCsv();
		csvLink.current?.link?.click();
	};

	const isFilterFetching =
		isUsersFetching || isTerminalsFetching || isLocationsFetching;
	const usersOptions = [USER_EMPTY_OPTION, ...users] || [];
	const terminalsOptions = [TERMINAL_EMPTY_OPTION, ...terminals] || [];
	const locationsOptions = [LOCATION_EMPTY_OPTION, ...locations] || [];

	return (
		<>
			<Spinner open={isFetchingCsv} />
			{!withoutTitle && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<h2>Search and Download Approved or Failed Transaction</h2>
				</Box>
			)}

			<ExportListFilter
				onSubmit={handleDownloadCsv}
				users={usersOptions}
				terminals={terminalsOptions}
				locations={locationsOptions}
				isFetching={isFilterFetching}
			/>

			<Box sx={{ display: "none" }}>
				<CSVLink
					data={csv || []}
					filename="transactions.csv"
					ref={csvLink}
					target="_blank"
				/>
			</Box>
		</>
	);
};
export default ExportList;
