import React from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	ListItemIcon,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import MenuItem from "./menuItem";
import { HasPermissions } from "components";
import { iconMenuStyle } from "helpers";

const SubMenu = ({
	name,
	handleChange,
	expanded,
	icon,
	menu,
	requiredPermissions,
	sectionName,
}) => {
	return (
		<HasPermissions requiredPermissions={requiredPermissions}>
			<Accordion
				expanded={expanded === sectionName}
				onChange={handleChange(sectionName)}
			>
				<AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
					<ListItemIcon sx={iconMenuStyle}>{icon}</ListItemIcon>
					{name}
				</AccordionSummary>
				<AccordionDetails>
					{menu.map(({ to, text, requiredPermissions }) => (
						<HasPermissions
							requiredPermissions={requiredPermissions}
							key={to}
						>
							<MenuItem to={to} text={text} key={to} />
						</HasPermissions>
					))}
				</AccordionDetails>
			</Accordion>
		</HasPermissions>
	);
};
export default SubMenu;
