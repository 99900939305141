import React from "react";
import { useForm } from "react-hook-form";
import { Container, Box, Button, Grid, Divider, Input } from "@mui/material";
import { ReactHookFormCurrencyInput, ReactHookFormInput } from "components";
import { required, useLocalStorage } from "helpers";
import { useHistory } from "react-router";

export const TransactionForm = ({ onSubmit }) => {
	const history = useHistory();
	const [terminal] = useLocalStorage("terminal", "");
	const defaultValues = {
		terminal_id: terminal?.terminal_id,
		terminal_name: terminal?.terminal_name,
		paymentsType: "Credit",
		transactionType: "Sale",
		actual_amount: "",
		reference_number: "",
	};

	const { handleSubmit, control } = useForm({
		mode: "onChange",
		defaultValues,
	});

	const handleOnSubmit = values => {
		const correctedValues = {
			...values,
			actual_amount: values.actual_amount.value,
		};
		onSubmit(correctedValues);
	};

	const toListPage = () => history.push("/transactions");

	return (
		<Container component="main" className="formWrapper">
			<Box
				component="form"
				noValidate
				onSubmit={handleSubmit(handleOnSubmit)}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} md={4}>
						<ReactHookFormInput
							name="terminal_name"
							label="Using Terminal*"
							control={control}
							disabled
							defaultValue=""
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<ReactHookFormInput
							name="paymentsType"
							label="Payments Type*"
							control={control}
							disabled
							defaultValue=""
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<ReactHookFormInput
							name="transactionType"
							label="Transaction Type*"
							control={control}
							disabled
							defaultValue=""
						/>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12} md={4}>
						<ReactHookFormCurrencyInput
							name="actual_amount"
							label="Amount*"
							control={control}
							autoFocus
							rules={{
								validate: { required },
							}}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<ReactHookFormInput
							name="reference_number"
							label="Reference Number*"
							control={control}
							defaultValue=""
							rules={{ validate: { required } }}
						/>
					</Grid>

					<Input
						name="terminal_id"
						disabled
						defaultValue=""
						type="hidden"
					/>

					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Button
							sx={{ mr: 3 }}
							type="submit"
							variant="contained"
							size="large"
						>
							Submit
						</Button>
						<Button
							variant="outlined"
							size="large"
							onClick={toListPage}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
};
