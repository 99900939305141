import { axiosWithHandler } from "helpers";

export const currentUserService = {
	getCurrentUser,
	getUsers,
	updateProfile,
	changePassword,
	addUser,
	getUserData,
	updateUser,
	activateUser,
	deactivateUser,
};

const PAGE_SIZE = 40;

function getCurrentUser() {
	const options = {
		method: "get",
		url: "/api/users/current",
	};

	return axiosWithHandler(options);
}

function getUsers({ page, page_size, sort_by, sort_desc }) {
	const options = {
		method: "get",
		url: "/api/companies/current/users",
		params: {
			page_index: page - 1,
			page_size: page_size || PAGE_SIZE,
			sort_by,
			sort_desc,
		},
	};

	return axiosWithHandler(options);
}

function updateProfile(data) {
	const options = {
		method: "put",
		url: "/api/users/current",
		data,
	};

	return axiosWithHandler(options);
}

function changePassword({ current_password, new_password }) {
	const options = {
		method: "post",
		url: "/api/users/current/change_password",
		data: {
			current_password,
			new_password,
		},
	};

	return axiosWithHandler(options);
}

function addUser(data) {
	const options = {
		method: "post",
		url: "/api/companies/current/users",
		data,
	};

	return axiosWithHandler(options);
}

function getUserData(id) {
	const options = {
		method: "get",
		url: `/api/companies/current/users/${id}`,
	};

	return axiosWithHandler(options);
}

function updateUser(data, id) {
	const options = {
		method: "put",
		url: `/api/companies/current/users/${id}`,
		data,
	};

	return axiosWithHandler(options);
}

function activateUser(id) {
	const options = {
		method: "post",
		url: `/api/companies/current/users/${id}/activate`,
	};

	return axiosWithHandler(options);
}

function deactivateUser(id) {
	const options = {
		method: "post",
		url: `/api/companies/current/users/${id}/deactivate`,
	};

	return axiosWithHandler(options);
}
