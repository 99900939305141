import React from "react";
import { useAlert } from "react-alert";
import { useCurrentTerminal, useCurrentUser } from "queries";
import { useParams } from "react-router";
import { UserForm } from "components";
import { DataContainer } from "components/dataContainer";

const UpdateUser = () => {
	const { id } = useParams();
	const alert = useAlert();
	const { useGetUserQuery, useUpdateUserQuery } = useCurrentUser();
	const { data, isFetching } = useGetUserQuery(id);
	const { mutateAsync: handleUpdateUser } = useUpdateUserQuery(id, alert);

	const { useTerminalsSelectQuery } = useCurrentTerminal();
	const { data: terminals = [], isFetching: isTerminalsFetching } =
		useTerminalsSelectQuery();

	const terminalOptions = [{ value: "all", name: "All" }, ...terminals] || [];

	return (
		<>
			<h2>Update user</h2>
			<DataContainer
				isFetching={isFetching || isTerminalsFetching}
				isData={!!data}
			>
				<UserForm
					onSubmit={handleUpdateUser}
					data={data}
					update
					terminals={terminalOptions}
				/>
			</DataContainer>
		</>
	);
};
export default UpdateUser;
